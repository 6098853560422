import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

const MultiSelect = ({
  label,
  required,
  options,
  value,
  onChange,
  error,
  placeholder,
  name,
  optionKey,
  optionKey1,
}) => {
  const [selectedValues, setSelectedValues] = useState(value);
  const handleChange = (event, newValue) => {
    const filteredValue = newValue.filter((item, index, self) =>
        index === self.findIndex((t) => t.id === item.id)
      );

    setSelectedValues(filteredValue);
    onChange(filteredValue);
  };

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  return (
    <div className="w-full flex flex-col">
      <label className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">
        {label} {required && <span className="text-red-400"> *</span>}
      </label>
      <Autocomplete
        multiple
        disableCloseOnSelect
        sx={{
          background: 'white',
          borderRadius: '10px',
          border: '0px solid #E2E8F0',
          width: 'full',
          position: 'relative',
          zIndex: '0',
        }}
        options={options?.map((option) => (
          {
          id: option?.id?.toString(),
          [optionKey]: (`${option?.[optionKey]}${optionKey1 ? ` - ${option?.[optionKey1]}` : '' }` || '') ,
          selected: value?.some(
            (item) => item.id == option?.id.toString()
          ),
        }))}
        value={selectedValues}
        defaultValue={[]}
        onChange={handleChange}
        renderTags={(values, getTagProps) => {
          setSelectedValues(values);
          return values.map((option, index) => (
            <Chip
              variant="outlined"
              sx={{
                backgroundColor: '#F0F1F2',
                borderRadius: '8px',
                border: 'none',
              }}
              label={`${option?.[optionKey]}${optionKey1 ? ` - ${option?.[optionKey1]}` : ''}` || ''}
              {...getTagProps({ index })}
              key={option.id}
            />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              padding: '0px',
              margin: '0px',
            }}
            placeholder={placeholder}
          />
        )}
        getOptionLabel={(option) => `${option?.[optionKey]}${optionKey1 ? ` - ${option?.[optionKey1]}` : ''}` || ''}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            key={option.id}
          >
            {`${option?.[optionKey]}` || ''}
            <Checkbox
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '&.Mui-checked svg path': {
                  color: '#001fff',
                },
              }}
              style={{ marginRight: 8 }}
              checked={selectedValues
                ?.map((item) => +item?.id)
                ?.includes(parseInt(option.id))}
            />
          </li>
        )}
      />
       {error && <Typography variant="caption" color={"error"}>
        {error}
      </Typography>} 
    </div>
  );
};



export default MultiSelect;
