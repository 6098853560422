import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
export function Button(props) {
  return (
    <div style={{...props?.divStyle}}>
      <button
        id={props.id}
        type={props.type}
        onClick={props.onClick}
        {...props}
        className={
          props.isLight
            ? `w-full inline-flex justify-center items-center px-4 py-2.5 text-white bg-[#118740] rounded-md text-sm ${props.abc} hover:from-[#3552D8] hover:to-[#1190E4]`
            : props.isBordered
            ? `w-full inline-flex justify-center items-center font-bold px-4 py-2.5 text-[#138CE3] border border-[#138CE3] bg-white rounded-md text-sm ${props.abc} hover:from-pink-500 hover:to-[#3552D8]`
            : `w-full inline-flex justify-center items-center px-4 py-2.5 text-white bg-gradient-to-b from-[#1190E4] to-[#3552D8] rounded-md text-sm ${props.abc} hover:from-pink-500 hover:to-[#3552D8] `
        }
        style={{ whiteSpace: "nowrap"}}
      >
        {props.type === "submit" && (
          <props.icon className="w-5 h-5 mr-1 hover:animate-pulse" />
        )}
        <span className="hover:animate-pulse">{props.label}</span>
        {props?.searchicon && (
          <MagnifyingGlassIcon className="w-5 h-5 hover:animate-spin " />
        )}
      </button>
    </div>
  );
}
