import React,{ useEffect,useRef,useState } from "react";
import { Input,TextArea } from "./Input";

import { TrashIcon,ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Model from "./Model";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "./Checkbox";
import { SortData } from "./SortData";
import Select from "./Select";
import { PlusIcon } from "@heroicons/react/20/solid";
import Records from "./Records";
import Breadcrumbs from "./Breadcrumbs";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { Listbox } from "@headlessui/react";
import { CKEditor } from "ckeditor4-react";
import { Api } from "../Api/Api";
import { Typography } from "@mui/material";
import { ErrorToast,SuccessToast } from "./toast";
import { useDebounce } from "../hooks/use-debounce";
import LatexTextRenderer from "./utils/LatexTextRenderer";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";
import LatexTextRendererMcq from "./utils/LatexTextRendererMcq";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MdClose } from "react-icons/md";

const QuestionReportData = (props) => {
  const access = {
    ...props?.access?.filter((e) => e?.name === "Approve Question")?.[0],
    // approve: true,
  };
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);

  let userData = getDecryptedDataFromLocalStorage("userData");
  const [isImageModal,setIsImageModal] = useState(false);

  const [isModel,setIsModel] = useState(false);
  const [openModel,setOpenModel] = useState(false);
  const [currentPage,setCurrentPage] = useState(1);
  const [recordsPerPage,setRecordsPerPage] = useState(50);
  const [questionData,setQuestionData] = useState([]);
  const [questionCount,setQuestionCount] = useState(0);
  const [search,setSearch] = useState("");
  const debouncedValue = useDebounce(search,900);
  const [isLoader,setIsLoader] = useState(false);
  const [isResetFilter,setIsResetFilter] = useState(false);
  const [statusData,setVersionData] = useState([]);
  const [label, setLabel] = useState('');
  const [titleData,setTitleData] = useState([
    {
      title: "NO",
      sort: 1,
      fieldName: "no",
    },
    {
      title: "QUESTIONNO",
      sort: 1,
      fieldName: "questionno",
    },
    {
      title: "QUESTION",
      sort: 1,
      fieldName: "question",
    },
    {
      title: "ANSWER",
      sort: 1,
      fieldName: "question",
    },
    {
      title: "Description",
      sort: 1,
      fieldName: "Description",
    },
    {
      title: "USER NAME",
      sort: 1,
      fieldName: "USER NAME",
    },
    {
      title: "TYPE",
      sort: 1,
      fieldName: "type",
    },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "status",
    },

    {
      title: "standard",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "semester",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "subject",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "chapter",
      sort: 1,
      fieldName: "chapter",
    },
    {
      title: "APPROVED BY",
      sort: 1,
      fieldName: "approvedby",
    },
    {
      title: "USERCOMMENT",
      sort: 1,
      fieldName: "USERCOMMENT",
    },
    {
      title: "PHONE",
      sort: 1,
      fieldName: "PHONE",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.view ? "VIEW" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [filter,setFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
    questionType: "",
    status: "",
  });

  const [boardData,setBoardData] = useState([]);
  const [mediumData,setMediumData] = useState([]);
  const [subjectData,setSubjectData] = useState([]);
  const [standardData,setStandardData] = useState([]);
  const [semesterData,setSemesterData] = useState([]);
  const [chapterData,setChapterData] = useState([]);
  const [qTypeData,setQTypeData] = useState([]);
  const [questionReportDetails,setQuestionReportDetails] = useState();
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(questionCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [ApproveQuestionId,setApproveQuestionId] = useState();

  const [selectedValue,setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handlelabelchange = (e) => {
    setLabel(e.target.value);
  };

  const handleFilter = (name,value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
    if (name === "board") {
      setFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "medium") {
      setFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        chapter: "",
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "standard") {
      setFilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        chapter: "",
      }));
      setSemesterData([]);
      setSubjectData([]);
      setChapterData([]);
    }

    if (name === "semester") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
        subject: "",
      }));
      setSubjectData([]);
      setChapterData([]);
    }
    if (name === "subject") {
      setFilter((oldValue) => ({
        ...oldValue,
        chapter: "",
      }));
      setChapterData([]);
    }
  };

  const _getQuestion = async (filter,search,limit,offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/questionsReport?board=${filter?.board?.id || ""}&medium=${filter?.medium?.id || ""
        }&standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}&chapter=${filter?.chapter?.id || ""
        }&qtype=${filter?.questionType?.id || ""}&search=${search || ""
        }&status=${filter?.status?.name || ""}&limit=${limit || 10}&offset=${offset || 1}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setQuestionData(req?.data?.data);
        setQuestionCount(req?.data?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const _deleteApproveQuestion = async (id) => {
    try {
      const { data } = await Api.postRequest(
        `api/questions/delete/${ApproveQuestionId}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getQuestion(filter,search,recordsPerPage,currentPage);
        setOpenModel(false);
        setApproveQuestionId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllBoards = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getAllMedium = async () => {
    try {
      const { data } = await Api.getRequest(
        `api/medium?board=${filter?.board?.id || ""}`
      );
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?medium=${filter?.medium?.id || ""}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error.message);
    }
  };
  const getChapterData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id || ""}&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}`;
      const { data } = await Api.getRequest(`api/chapter${body}`);
      const req = JSON.parse(data);
      setChapterData(req?.data);
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const getQTypeData = async () => {
    try {
      const { data } = await Api.getRequest(`api/qtype`);
      const req = JSON.parse(data);
      setQTypeData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStatusData = async () => {
    try {
      const { data } = await Api.getRequest(`api/questionsReport/status`);
      const req = JSON.parse(data);
      setVersionData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getAllBoards();
    getQTypeData();
    getStatusData();
  },[]);

  useEffect(() => {
    setSelectedValue(questionReportDetails?.status);
    setLabel(questionReportDetails?.usercomment);
  },[questionReportDetails]);

  useEffect(() => {
    if (filter["board"] !== "") {
      getAllMedium();
    }
  },[filter["board"]]);

  useEffect(() => {
    if (filter["medium"] !== "") {
      getStandardData();
    }
  },[filter["medium"]]);

  useEffect(() => {
    if (filter["standard"] !== "") {
      getSemesterData();
    }
  },[filter["standard"]]);
  useEffect(() => {
    if (filter["semester"] !== "") {
      getSubjectData();
    }
  },[filter["semester"]]);
  useEffect(() => {
    if (filter["subject"] !== "") {
      getChapterData();
    }
  },[filter["subject"]]);

  useEffect(() => {
    _getQuestion(filter,search,recordsPerPage,currentPage);
  },[currentPage,recordsPerPage]);

  const applyFilter = () => {
    _getQuestion(filter,search,recordsPerPage,currentPage);
  };


  const resetFilter = () => {
    setIsResetFilter(true);
    setFilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
      chapter: "",
      questionType: "",
    });
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
    setChapterData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getQuestion(filter,search,recordsPerPage,currentPage);
      setIsResetFilter(false);
    }
  },[isResetFilter]);

  const renderLatex = (text) => {
    const parts = text.split(/(\$[^\$]*\$)/);
    let renderedText = parts.map((part,index) => {
      // if (index % 2 === 0) {
      //   return part.includes("<") ? (
      //     <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
      //   ) : (
      //     part
      //   );
      // } else {
      return <LatexTextRenderer key={index} text={part} />;
      // }
    });
    return renderedText;
  };

  const handleReportStatus = async () => {
    const body = {
      status: selectedValue,
      usercomment: label,
    };
    try {
      const response = await Api.putRequest(
        `api/questionsReport/update/${questionReportDetails?.id}`,
        body
      );
      if (response?.statusCode === 200) {
        SuccessToast(response?.data?.message);
        setIsModel(false);
        _getQuestion(filter,search,recordsPerPage,currentPage);
      } else {
        ErrorToast(response?.data?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  return (
    <>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-0">
          <div className="flex-1 px-2 sm:px-0 w-full">
            <div className="w-full sm:px-0 flex md:flex-nowrap flex-wrap items-start gap-x-4">
              <div className="w-full">
                <Select
                  data={boardData || []}
                  value={filter?.board}
                  onChange={(e) => handleFilter("board",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Board"
                  type="board"
                  getBoard={getAllBoards}
                />
              </div>
              <div className="w-full">
                <Select
                  data={mediumData || []}
                  value={filter?.medium}
                  onChange={(e) => handleFilter("medium",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Medium"
                  type="medium"
                  getMedium={getAllMedium}
                />
              </div>{" "}
              <div className="w-full">
                <Select
                  data={standardData || []}
                  value={filter?.standard}
                  onChange={(e) => handleFilter("standard",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Standard"
                  type={"standard"}
                  getStandard={getStandardData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={semesterData || []}
                  value={filter?.semester}
                  onChange={(e) => handleFilter("semester",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Semester"
                  type={"semester"}
                  getSemester={getSemesterData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={subjectData || []}
                  value={filter?.subject}
                  onChange={(e) => handleFilter("subject",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Subject"
                  type={"subject"}
                  getSubject={getSubjectData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={chapterData || []}
                  value={filter?.chapter}
                  onChange={(e) => handleFilter("chapter",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All Chapter"
                  type={"chapter"}
                  getChapter={getChapterData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={qTypeData || []}
                  value={filter?.questionType}
                  onChange={(e) => handleFilter("questionType",e)}
                  placeholder="All Q.Type"
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  type={"qType"}
                  getQType={getQTypeData}
                />
              </div>
              <div className="w-full">
                <Select
                  data={statusData || []}
                  value={filter?.status}
                  onChange={(e) => handleFilter("status",e)}
                  className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                  placeholder="All status"
                  type={"status"}
                  getStandard={getStatusData}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  label={"SEARCH"}
                />
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => resetFilter()}
                  id=""
                  type="button"
                  label={"RESET"}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center  mt-2">
                <Model
                  title={"View Report Details"}
                  show={isModel}
                  onCancel={() => {
                    setIsModel(false);
                  }}
                  onClose={() => {
                    setIsModel(false);
                  }}
                  cancelText=""
                  okText=""
                  //   isLoading={isLoading}
                  //   disabled={isLoading}
                  width="max-w-2xl"
                >
                  <div>
                    <div className="flex items-start lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                      <div className="text-black font-medium font-poppins font-base ">
                        Type
                      </div>
                      <div className="flex">
                        <p className="text-[#7B809A] font-regular font-poppins font-base ">
                          {questionReportDetails?.type || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start border-t lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                      <div className="text-black font-medium font-poppins font-base ">
                        Description
                      </div>
                      <div className="flex">
                        <p className="text-[#7B809A] font-regular font-poppins font-base ">
                          {questionReportDetails?.description}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start border-t lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                      <div className="text-black font-medium font-poppins font-base ">
                        Image
                      </div>
                      <div
                        className="flex"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsModel(false);
                          setIsImageModal(true);
                        }}
                      >
                        <p className="text-[#7B809A] font-regular font-poppins font-base ">
                          <img
                            src={questionReportDetails?.image}
                            alt="Image"
                            style={{
                              height: "120px",
                              width: "110px",
                              marginLeft: "10px",
                            }} // Set your desired height and width here
                          />
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start border-t lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                      <div className="text-black font-medium font-poppins font-base ">
                        User
                      </div>
                      <div className="flex">
                        <p className="text-[#7B809A] font-regular font-poppins font-base ">
                          {questionReportDetails?.user?.userInfo?.first_name}, {questionReportDetails?.user?.userInfo?.mobile}
                        </p>
                      </div>
                    </div>
                  
                    <div className="flex items-start border-t lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                    <div className="text-black font-medium font-poppins font-base ">
                        User comment 
                      </div>
                                      <div className="flex">
                                        <TextArea
                                          id="label"
                                          name="label"
                                          placeholder="Type label here..."
                                          type="text"
                                          autoComplete="given-name"
                                          required
                                          className="w-96 mb-2"  // Increase the width
                                          onChange={handlelabelchange}
                                          value={label}
                                        />
                                        <Typography
                                          variant="caption"
                                          color={"error"}
                                        >
                                          {/* {!questionReportDetails?.label ? error?.label : ""} */}
                                        </Typography>
                                      </div>
                    </div>
                                                        
                 
                    <div className="flex items-start border-t lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                      <div className="text-black font-medium font-poppins font-base ">
                        Status
                      </div>
                      <div className="flex">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="pending"
                            control={<Radio />}
                            label="Pending"
                          />
                          <FormControlLabel
                            value="approved"
                            control={<Radio />}
                            label="Approved"
                          />
                          <FormControlLabel
                            value="no-change-required"
                            control={<Radio />}
                            label="No change required"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    {questionReportDetails?.status !== "approved" &&<div className="flex items-start mt-3 lg:justify-between flex-col space-x-12 sm:flex-row sm:space-y-0 py-3 px-8">
                      <button
                        style={{
                          width: "100%",
                          borderRadius: "30px",
                          padding: "15px",
                        }}
                        onClick={handleReportStatus}
                        className="text-white bg-gradient-to-b from-[#1190E4] to-[#1976d2] hover:from-[#1976d2] hover:to-[#0d47a1] focus:outline-none font-medium text-sm px-5 py-2.5 mt-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                      >
                        SUBMIT
                      </button>
                    </div>}


                  </div>
                </Model>

                <div
                  style={{
                    display: isImageModal ? "flex" : "none",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      // backgroundColor: "#fff",
                      // padding: "20px",
                      borderRadius: "8px",
                      // maxWidth: "600px",
                      // width: "100%",
                      position: "relative",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        margin: "30px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* Close icon in the top right corner */}
                      {/* <div
                        style={{
                          position: "absolute",
                          top: "35px",
                          right: "30px",
                          cursor: "pointer",
                        }}
                        // onClick={closeCartModal}
                      >
                        <MdClose size={24} />
                      </div> */}

                      <img
                        onClick={() => {
                          setIsImageModal(false);
                          setIsModel(true);
                        }}
                        src={questionReportDetails?.image}
                        alt="Image"
                        style={{
                          height: "80%",
                          width: "80%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-1">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "90vh",
                      margin: "0 auto",
                      padding: "10px",
                    }}
                  >
                    <div className="flex justify-between items-center mt-1 px-8 mb-4">
                      <div style={{ display: "flex",gap: '5px' }}>
                        <Input
                          id=""
                          name="Search"
                          type="text"
                          placeholder="Search here..."
                          autoComplete="given-name"
                          required
                          className="w-72"
                          onChange={(e) => {
                            setSearch(e?.target?.value);
                            setCurrentPage(1);
                          }}
                          value={search}
                        />
                        <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setRecordsPerPage={setRecordsPerPage}
                          setCurrentPage={setCurrentPage}
                          dataCount={questionCount}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "80vh",
                        height: "80vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && questionData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              {" "}
                              {/* <th
                                  scope="col"
                                  className="relative w-12 px-6 sm:w-16 sm:px-8"
                                ></th> */}
                              {titleData?.map(
                                (v,ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "VIEW" ||    v.title === "EDIT" || v.title === "PHONE"||v.title === "USERCOMMENT" ? "sticky" : "",
                                        right: v.title === "USERCOMMENT" ? "300px": v.title === "PHONE" ? "161px" :  v.title === "EDIT"? "100px" :v.title === "VIEW" ? "0" : "",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span>{v.title}</span>
                                        {v.title !== "VIEW" && v.title !== "PHONE"&& v.title !== "EDIT"  && v.title !== "USERCOMMENT" && (
                                          <span>
                                            <ChevronUpDownIcon
                                              onClick={() => {
                                                let res = SortData(
                                                  questionData,
                                                  v.fieldName,
                                                  v.sort
                                                );

                                                let copyData = [...titleData];
                                                copyData[ind].sort =
                                                  v.sort > 0 ? -1 : 1;
                                                setTitleData(copyData);
                                                setQuestionData(res);
                                              }}
                                              className="w-5 h-5"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td colSpan={6} style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              questionData?.length > 0 &&
                              questionData?.map((data,id) => (
                                <tr
                                  key={id}
                                  className="my-4 px-8 text-[#000] font-bold text-sm align-top"

                                  style={{
                                    // margin: "3px",
                                    // padding: "6px",
                                    // color: "#7B809A",
                                    // fontSize: "14px",

                                    background:
                                      data?.book_question === "1"
                                        ? "#b7ddf6"
                                        : "",
                                        backgroundColor:
                                        data?.status === "approved"
                                          ? "#8cfb8c" // Color for approved
                                          : data?.status === "pending"
                                            ? "#fb6868" // Color for pending
                                            : "#fff"

                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "7%",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {" "}
                                    {data.id}
                                  </td>

                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "7%",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {" "}
                                    {data.questionId}
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "40%",
                                      whiteSpace: "normal",
                                      border: "1px solid #000",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        width: "100%",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {data?.question?.m_equation !== "1" && (
                                        <>
                                          {data?.question?.m_equation !==
                                            "1" && (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    data?.question?.question,
                                                }}
                                              />
                                            )}
                                          {data?.question?.QtypeInfo?.name ===
                                            "MCQ" && (
                                              <div>
                                                <ul>
                                                  <li>
                                                    A .{data?.question?.option1}
                                                  </li>
                                                  <li>
                                                    B .{data?.question?.option2}
                                                  </li>
                                                  <li>
                                                    C .{data?.question?.option3}
                                                  </li>
                                                  <li>
                                                    D .{data?.question?.option4}
                                                  </li>
                                                </ul>
                                              </div>
                                            )}
                                        </>
                                      )}
                                      {data?.question?.m_equation === "1" &&
                                        data?.question?.QtypeInfo?.name ===
                                        "MCQ" && (
                                          <div
                                            className="mathjax-rendered"
                                            style={{
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  data?.question?.finalquestion
                                              }}
                                            />
                                            <div
                                              className="mathjax-rendered"
                                              style={{
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              <LatexTextRendererMcq
                                                text={data?.question}
                                              />
                                            </div>
                                            {/* <div>
                                                 
                                                  <ul>
                                                    {options.map(
                                                      (option, index) => (
                                                        <LatexTextRendererMcq
                                                          key={index}
                                                          option={option}
                                                        />
                                                      )
                                                    )}
                                                  </ul>
                                                </div> */}
                                          </div>
                                        )}
                                      {data?.question?.m_equation === "1" &&
                                        data?.question?.QtypeInfo?.name !==
                                        "MCQ" && (
                                          <div
                                            className="mathjax-rendered"
                                            style={{
                                              // wordWrap: "break-word",
                                              flexDirection: "row",
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {data?.question?.qtype != 1 && <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data?.question?.finalquestion,
                                                }}
                                              />}
                                          </div>
                                        )}{" "}
                                      {data?.question?.m_equation !== "1" &&
                                        data?.question?.QtypeInfo?.name?.toLowerCase().includes("match the following") && (
                                          <div>
                                            <table
                                              style={{
                                                // width: "100%",
                                                borderCollapse: "collapse",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Question
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Answer
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {data?.question?.perfact_metch.map(
                                                  (item,index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {item.question}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {item.answer}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                            {/* "ABC" */}
                                          </div>
                                        )}
                                      {data?.question?.m_equation === "1" &&
                                        data?.question?.QtypeInfo?.name?.toLowerCase().includes("match the following") && (
                                          <div>
                                            <table
                                              style={{
                                                // width: "100%",
                                                borderCollapse: "collapse",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Question
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Answer
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {data?.question?.perfact_metch.map(
                                                  (item,index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {/* {item.question} */}
                                                        <LatexTextRenderer
                                                          text={item?.question}
                                                        />{" "}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        {/* {item.answer} */}
                                                        <LatexTextRenderer
                                                          text={item.answer}
                                                        />{" "}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                            {/* "ABC" */}
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      width: "40%",
                                      whiteSpace: "normal",
                                      border: "1px solid #000",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        width: "100%",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {data?.question?.m_equation !== "1" &&
                                        data?.question?.QtypeInfo?.name !==
                                        "તફાવત (Difference)" &&
                                        data?.question?.QtypeInfo.name !==
                                        "ખરા ખોટા (True-False)" &&
                                        data?.question?.QtypeInfo?.name !==
                                        "MCQ" ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: data?.question?.answer,
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {data?.question?.QtypeInfo?.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.question?.answer === "0" ? (
                                        <div>False</div>
                                      ) : data?.question?.QtypeInfo?.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.question?.answer === "1" ? (
                                        <div> True</div>
                                      ) : (
                                        ""
                                      )}

                                      {data?.question?.QtypeInfo.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.question?.answer === "False" ? (
                                        <div>False</div>
                                      ) : data?.question?.QtypeInfo.name ===
                                        "ખરા ખોટા (True-False)" &&
                                        data?.question?.answer === "True" ? (
                                        <div> True</div>
                                      ) : (
                                        ""
                                      )}
                                      {data?.question?.m_equation !== "1" &&
                                        data?.question?.QtypeInfo.name !==
                                        "તફાવત (Difference)" &&
                                        data?.question?.QtypeInfo?.name ===
                                        "MCQ" ? (
                                        <> {data?.question?.answer}</>
                                      ) : (
                                        ""
                                      )}
                                      {data?.question?.QtypeInfo.name ===
                                        "તફાવત (Difference)" ? (
                                        <div>
                                          <table
                                            style={{
                                              borderCollapse: "collapse",
                                            }}
                                          >
                                            <thead>
                                              {/* Conditionally render an additional row for the last item in data.perfact_metch */}
                                              {data?.question?.perfact_metch
                                                .length > 0 && (
                                                  <tr
                                                    style={{
                                                      backgroundColor: "#e1e1e1",
                                                    }}
                                                  >
                                                    <th
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {
                                                        data.question
                                                          ?.perfact_metch[
                                                          data.question
                                                            ?.perfact_metch
                                                            .length - 1
                                                        ].question
                                                      }
                                                    </th>
                                                    <th
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {
                                                        data.question
                                                          ?.perfact_metch[
                                                          data.question
                                                            ?.perfact_metch
                                                            .length - 1
                                                        ].answer
                                                      }
                                                    </th>
                                                  </tr>
                                                )}
                                            </thead>
                                            <tbody>
                                              {/* Render all items in data.perfact_metch except the last one */}
                                              {data?.question?.perfact_metch
                                                .slice(0,-1)
                                                .map((item,index) => (
                                                  <tr key={index}>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {item.question}
                                                    </td>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      {item.answer}
                                                    </td>
                                                  </tr>
                                                ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {data?.question?.m_equation === "1" && (
                                        <div
                                          className="mathjax-rendered"
                                          style={{
                                            // wordWrap: "break-word",
                                            flexDirection: "row",
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                           <div
                                                dangerouslySetInnerHTML={{
                                                  __html: data?.question?.finalAnswer,
                                                }}
                                              />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data.description}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data?.user?.userInfo?.first_name}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data?.type || "-"}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data?.status}
                                  </td>

                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data.question.StandardInfo.name}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data.question.SemesterInfo.semester}
                                  </td>

                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data.question.SubjectInfo.subject}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      border: "1px solid #000",
                                    }}
                                  >
                                    {data.question.ChapterInfo.chapter}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      width: "130px",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    <div style={{ width: "130px" }}>
                                      {data?.userinfo?.username ?? '-'}

                                      {/* {data?.UserInfo?.username &&
                                        data?.updatedAt && (
                                          <>
                                            User: {data?.UserInfo?.username}
                                            <br />
                                            --------------------------
                                            <br />
                                            updatedAt:{" "}
                                            {data.updatedAt &&
                                              new Date(
                                                data.updatedAt
                                              ).toLocaleString("en-US", {
                                                timeZone: "Asia/Kolkata",
                                              })}
                                          </>
                                        )} */}
                                    </div>



                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      position: "sticky",
                                      right: "260px",
                                      border: "1px solid #000",

                                      backgroundColor:
                                      data?.status === "approved"
                                        ? "#8cfb8c" // Color for approved
                                        : data?.status === "pending"
                                          ? "#fb6868" // Color for pending
                                          : "#fff"
                                    }}
                                  >
                                    {data?.usercomment}
                                  </td>
                                  <td
                                    className="lg:table-cell px-6 py-2.5"
                                    style={{
                                      position: "sticky",
                                      right: "160px",
                                      border: "1px solid #000",

                                      backgroundColor:
                                      data?.status === "approved"
                                        ? "#8cfb8c" // Color for approved
                                        : data?.status === "pending"
                                          ? "#fb6868" // Color for pending
                                          : "#fff"
                                    }}
                                  >
                                    {data?.user?.userInfo?.mobile}
                                  </td>



                                  {access?.edit && (
                                      <td
                                        className="relative whitespace-nowrap  px-2"
                                        style={{
                                          position: "sticky",
                                          right: access?.delete
                                            ? "100px"
                                            : "0px",
                                          border: "1px solid #000",
                                          background:
                                            data?.book_question === "1"
                                              ? "#b7ddf6"
                                              : "white",
                                        }}
                                      >
                                        <ActionButton
                                          key={data.key}
                                          action="edit"
                                          onClick={() =>
                                            window.open(
                                              `/question/editquestion/${data.questionId}`,
                                              "_blank"
                                            )
                                          }
                                        />
                                      </td>
                                    )}
                                  {access?.view && (
                                    <td
                                      className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0",
                                        background:"white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="view"
                                        onClick={() => {
                                          setIsModel(true);
                                          setQuestionReportDetails(data);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={questionData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={questionCount}
                    />
                  </section>
                </div>

                {/* Delete */}

                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
                of this Data will be permanently removed from our
                servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setApproveQuestionId("");
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setApproveQuestionId("");
                  }}
                  onDelete={() => {
                    // _deleteApproveQuestion();
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionReportData;
