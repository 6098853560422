import React,{ useEffect,useState } from "react";
import MasterTitle from "./MasterTitle";
import { Input } from "./Input";
import { Button } from "./Button";
import { Api } from "../Api/Api";
import { ErrorToast,SuccessToast } from "./toast";
import { Typography } from "@mui/material";

const CoinSettings = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];

  const [questionReportCoins,setQuestionReportCoins] = useState(0);
  const [quizCompetitionCoins,setQuizCompetitionCoins] = useState(0);
  const [referEarnCoins,setReferEarnCoins] = useState(0);
  const [CoinToRupees,setCoinToRupees] = useState(0);
  const [error,setError] = useState({});

  const getCoinSettings = async () => {
    try {
      const data = await Api.getRequest(`api/userSetting/get`);
      const req = JSON.parse(data.data);

      const coinSettings = req?.data?.reduce((acc,setting) => {
        acc[setting.key] = setting.value;
        return acc;
      },{});
      setQuestionReportCoins(coinSettings?.question || 0);
      setQuizCompetitionCoins(coinSettings?.quiz || 0);
      setReferEarnCoins(coinSettings?.refern || 0);
      setCoinToRupees(coinSettings?.cointorupee || 0);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };


  useEffect(() => {
    getCoinSettings();
  },[]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (!questionReportCoins) {
      formIsValid = false;
      errors["questionReport"] = "Please enter coins for question report.";
    }
    if (!quizCompetitionCoins) {
      formIsValid = false;
      errors["quizCompetition"] = "Please enter coins for quiz competition.";
    }
    if (!referEarnCoins) {
      formIsValid = false;
      errors["referEarn"] = "Please enter coins for refer and earn.";
    }
    if (!CoinToRupees) {
      formIsValid = false;
      errors["coinToRupee"] = "Please enter coin to rupee.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async () => {
    if (validation()) {
      try {
        // Prepare an array of settings for update
        const body = [
          {
            key: "question",
            value: questionReportCoins,
          },
          {
            key: "quiz",
            value: quizCompetitionCoins,
          },
          {
            key: "refern",
            value: referEarnCoins,
          },
          {
            key: "cointorupee",
            value: CoinToRupees,
          },
        ];

        // Send the request to update the coin settings
        const response = await Api.postRequest(`api/userSetting/create`,body);
        const req2 = JSON.parse(response?.data);
        if (req2?.status === 200) {
          getCoinSettings();
          SuccessToast(req2?.message);
        }
      } catch (error) {
        ErrorToast(error?.message);
      }
    }
  };


  return (
    <div className="w-full sm:px-6">
      <div>
        <div className="sm:mb-0 overflow-hidden ">
          <div className="flex flex-col mt-4">
            <section className="bg-white rounded-2xl flex flex-col shadow-sm min-h-[calc(100vh-40px-16px-24px-38px-50px-40px)]">
              <div className="px-8 ">
                <div className="mt-10 mb-3">
                  <MasterTitle
                    title="Coin Settings"
                  />
                </div>

                <div className="max-w-xl">
                  <div className="w-full space-y-6">
                    <Input
                      label="1 Coin = How Many Rupees"
                      type="number"
                      name="coinToRupees"
                      required
                      placeholder="Enter value in rupees..."
                      className="mb-2"
                      value={CoinToRupees || ""}
                      onChange={(e) => setCoinToRupees(e.target.value)}
                      min="0"
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {error?.questionReport}
                    </Typography>
                    <Input
                      label="Coins for 1 Question Report"
                      type="number"
                      name="questionReportCoins"
                      required
                      placeholder="Enter number of coins..."
                      className="mb-2"
                      value={questionReportCoins || ""}
                      onChange={(e) => setQuestionReportCoins(e.target.value)}
                      min="0"
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {error?.questionReport}
                    </Typography>

                    <Input
                      label="Coins for 1 Quiz Competition"
                      type="number"
                      name="quizCompetitionCoins"
                      required
                      placeholder="Enter number of coins..."
                      className="mb-2"
                      value={quizCompetitionCoins || ""}
                      onChange={(e) => setQuizCompetitionCoins(e.target.value)}
                      min="0"
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {error?.quizCompetition}
                    </Typography>

                    <Input
                      label="Coins for Refer and Earn"
                      type="number"
                      name="referEarnCoins"
                      required
                      placeholder="Enter number of coins..."
                      className="mb-2"
                      value={referEarnCoins || ""}
                      onChange={(e) => setReferEarnCoins(e.target.value)}
                      min="0"
                    />
                    <Typography
                      className="my-2"
                      variant="caption"
                      color={"error"}
                    >
                      {error?.referEarn}
                    </Typography>



                    <div className="flex items-center flex-col sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                      {access?.edit && (
                        <div className="w-96">
                          <Button
                            type="button"
                            label={"SUBMIT"}
                            abc="w-full"
                            onClick={handleSubmit}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinSettings;
