import React, { useEffect, useRef, useState } from "react";
import { Input, TextArea } from "./Input";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import Model from "./Model";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import aaiscoursebanner from "../Image/aaiscoursebanner.png";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import Breadcrumbs from "./Breadcrumbs";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { useDebounce } from "../hooks/use-debounce";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast, WarningToast } from "./toast";
import Selected from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import { CKEditor } from "ckeditor4-react";
import Select from "./Select";
import { editorUrl } from "./constants/CkeditorUrl";

const JobComment = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Job")?.[0];
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const cancelButtonRef = useRef(null);
  const [titleData, setTitleData] = useState([
    {
      title: "COMMENTS",
      sort: 1,
      fieldName: "comments",
    },
    {
      title: "RETTING",
      sort: 1,
      fieldName: "ratting",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [NotificationData, setNotificationData] = useState([]);
  const [search, setSearch] = useState("");
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount ?? 0  / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleteType, setDeleteType] = useState("single");
  const [SelectNotificationId, setSelectNotificationId] = useState();
  const [error, setError] = useState({});
  const [SelectUserId, setSelectUserId] = useState();
  const [selectAll, setSelectAll] = useState(false);
console.log(NotificationData, "NotificationData");
  const _getNotificationData = async (limit, offset) => {
    try {
      setIsLoader(true);
      setSelectAll(false);
      const { data } = await Api.getRequest(
        `api/jobComment/get/?limit=${limit || 50}&offset=${offset || 0}&search=${search || ""
        }`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setNotificationData(req?.data);
        setDataCount(req?.totalCount);
        setCheckedItems([]);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };


  useEffect(() => {
    _getNotificationData(recordsPerPage, currentPage);
  }, [recordsPerPage, currentPage]);

  const applyFilter = () => {
    _getNotificationData(recordsPerPage, currentPage);
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      const allIds = NotificationData.map((data) => data.id);
      setCheckedItems(allIds);
    }
    setSelectAll(!selectAll);
  };




  const _deleteNotification = async () => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        data = await Api.postRequest(`api/jobComment/delete`, body);
      } else {
        const body = {
          ids: [SelectNotificationId],
        };
        data = await Api.postRequest(`api/jobComment/delete`, body);
      }
      let newData = JSON.parse(data?.data)
      if (newData?.status === 200) {
        SuccessToast(newData?.message);
        _getNotificationData(recordsPerPage, currentPage);
        setIsModel(false);
        setCheckedItems([]);
        setSelectUserId([]);
        setSelectNotificationId("")
        setOpenModel(false);
      } else {
        ErrorToast(newData?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };


  const [checkedItems, setCheckedItems] = useState([]);
  const handleCheckboxChange = (id) => {
    setDeleteType("multi");
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div className="w-full sm:px-2">
      <div>
        <div className="flex justify-between items-center mt-3">
          <div>
            <MasterTitle
              title="Job Comment"
              description="Below are the list of all the Job Comment."
            />
          </div>
          <div className="flex items-center space-x-2">
            <div>
              {access?.delete && (
                <Button
                  id=""
                  type="submit"
                  icon={TrashIcon}
                  label={"DELETE COMMENT"}
                  onClick={() => {
                    setDeleteType("multi");
                    if (checkedItems.length > 0) {
                      setOpenModel(true);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <section className="bg-white rounded-2xl flex flex-col shadow-sm " style={{
          width: "100%",
          height: "85vh",
          margin: "0 auto",
          padding: "10px",
        }}>
          <div className="flex justify-between items-center mt-6 px-8 mb-8">
            <div style={{ display: "flex", gap: '5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                value={search}
              />
              <Button
                onClick={() => applyFilter()}
                id=""
                type="button"
                // label={"SEARCH"}
                searchicon={true}
              />
            </div>
            <div>
              <Records
                setCurrentPage={setCurrentPage}
                recordsPerPage={recordsPerPage}
                setRecordsPerPage={setRecordsPerPage}
                dataCount={dataCount}
              />
            </div>
          </div>
          <div className=" overflow-y-auto">
            <div
              style={{
                overflowY: "clip",
                // height: "100vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && NotificationData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        className="px-6 py-3 whitespace-no-wrap"
                        scope="col"
                        style={{
                          width: "3%",
                          padding: "25px",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      {titleData.map(
                        (v, ind) =>
                          v?.title && (
                            <th
                              key={ind}
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {(
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          NotificationData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setNotificationData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      NotificationData?.map((data, id) => (
                        <tr
                          key={id}
                          className="my-3 px-3 text-[#000] font-reguler text-xs font-medium"
                        >
                          <td className="px-6 py-2.5 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8"  >
                            {data?.description}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data?.rating} / 5 
                          </td>
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setOpenModel(true);
                                  setSelectNotificationId(data?.id);
                                  setDeleteType("single");
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordsPerPage={recordsPerPage}
            data={NotificationData}
            numbers={numbers}
            npage={npage}
            lastIndex={lastIndex}
            firstIndex={firstIndex}
            dataCount={dataCount}
          />
        </section>
      </div>
      {/* Delete */}
      <DeletePopup
        title={"Delete"}
        show={openModel}
        description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
        onCancel={() => {
          setOpenModel(false);
          setSelectNotificationId("")
        }}
        onClose={() => {
          setOpenModel(false);
          setSelectNotificationId("")
        }}
        onDelete={() => {
          _deleteNotification();
        }}
        cancelButtonRef={cancelButtonRef}
        okText="Delete"
        cancelText="Cancel"
      />
      <FloatNotification
        show={showFloatNotification}
        title={"Successfully deleted!"}
        type="success"
        description={"Notification Deleted."}
        onClick={() => {
          setShowFloatNotification(false);
        }}
      />
    </div>
  );
};

export default JobComment;
