import React, { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import Model from "./Model";
import { Button } from "./Button";
import MasterTitle from "./MasterTitle";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import { Typography } from "@mui/material";
import { ErrorToast, SuccessToast } from "./toast";
import { Api } from "../Api/Api";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./NoDataFound";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";
import ActionButton from "./ActionButton";

const HelpVideoSection = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Topic")?.[0];
 
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [filter, setFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    chapter: "",
  });
  const [search, setSearch] = useState("");
  const [isResetFilter, setIsResetFilter] = useState(false);

  const [titleData, setTitleData] = useState([
    {
      title: "SR No.",
      sort: 1,
      fieldName: "type",
    },
    {
      title: "TITLE",
      sort: 1,
      fieldName: "title",
    },
    {
      title: "VIDEO",
      sort: 1,
      fieldName: "video",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [selectedId, setSelectedId] = useState(null);

  const [SelectTopicId, setSelectTopicId] = useState();
 
  const [TopicData, sethelpData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [topicQuestion, setTopicQuestion] = useState({
    title: "",
    video: "",
  });

  
  const [dataCount, setDataCount] = useState(0);
  const [error, setError] = useState({});
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const videoFileInputRef = useRef(null);
  const pdfFileInputRef = useRef(null);
  const otherFileInputRef = useRef(null);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
  
    if (!topicQuestion?.title) {
      formIsValid = false;
      errors["title"] = "Please enter title.";
    }

   

  
    // if (
    //   selectedOption &&
    //   typeof topicQuestion[selectedOption.toLowerCase()] === "string"
    // ) {
    //   const fileType =
    //     topicQuestion[selectedOption.toLowerCase()].toLowerCase();
    //   if (
    //     (selectedOption === "Video" && !fileType.includes("video")) ||
    //     (selectedOption === "PDF" && !fileType.includes("pdf"))
    //   ) {
    //     formIsValid = false;
    //     errors[
    //       selectedOption.toLowerCase()
    //     ] = `Please upload a valid ${selectedOption} file.`;
    //   } else {
    //     errors[
    //       selectedOption.toLowerCase()
    //     ] = `Please upload  ${selectedOption} file.`;
    //   }
    // }
    setError(errors);
    return formIsValid;
  };

  const handleUploadChange = async (value, e) => {
    const file = e.target.files[0];

    setSelectedOption(value);
    if (!file) {
      setError({
        ...error,
        [selectedOption.toLowerCase()]: "Please select a file.",
      });

      return;
    }
    if (value === "video" || value === "pdf" || value === "other") {
      try {
        const fileName = file.name;
        const fileUrl = file;
        // const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        setTopicQuestion({ ...topicQuestion, [value]: fileUrl });
      
       
        setSelectedVideo(file);
        setError({ ...error, [selectedOption.toLowerCase()]: "" });
      } catch (error) {
        setError({
          ...error,
          [selectedOption.toLowerCase()]: "Error getting file URL.",
        });
      }
    }
  };

  const _getTopic = async (filter, search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/helpVideos`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        sethelpData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };



 





  let lastUpdatedField;
  if (
    selectedOption === "video" ||
    selectedOption === "pdf" ||
    selectedOption === "other"
  ) {
    lastUpdatedField = selectedOption;
  }

  const handleClearFileInputs = () => {
    if (videoFileInputRef.current) {
      videoFileInputRef.current.value = "";
    }
    if (pdfFileInputRef.current) {
      pdfFileInputRef.current.value = "";
    }
    if (otherFileInputRef.current) {
      otherFileInputRef.current.value = "";
    }
  };

  console.log("topicQuestion---------->",topicQuestion);
  const handleAddModelSubmit = async () => {
    let userData = getDecryptedDataFromLocalStorage("userData");
    let data, data1, data2;

    let videoFormData = new FormData();
    let pdfFormData = new FormData();
    let otherFormData = new FormData();

    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        try {
         
         
         
            
     {
            let body = {
              title: topicQuestion?.title,
              video: topicQuestion?.video,
            };
            const res = await Api.putRequest(
              `api/helpVideos/update/${selectedId}`,
              body
            );
            if (res?.data?.status === 200) {
              SuccessToast(res?.data?.message);
              _getTopic(filter, search, recordsPerPage, currentPage);
              setTopicQuestion({
                title: "",
                video: "",
              });
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast("Something Wrong");
            }
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
         {
            let body = {
              title: topicQuestion?.title,
              video: topicQuestion?.video,
            };
            console.log("body-------", body);
            const response = await Api.postRequest("api/helpVideos/create", body);
            const req = JSON.parse(response.data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              _getTopic(filter, search, recordsPerPage, currentPage);
              handleClearFileInputs();
              // videoFileInputRef.current.value = "";
              // pdfFileInputRef.current.value = "";
              // otherFileInputRef.current.value = "";

              setTopicQuestion((prevState) => ({
                ...prevState,
                title: "",
                video: "",
              
              }));
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              // setIsModel(false);
              setSelectedId("");
            } else {
              ErrorToast("Something Wrong");
            }
          }
        } catch (error) {
          ErrorToast(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/helpVideos/getById/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
         
          setTopicQuestion({
            title: req?.data?.title || "",
            video: req?.data?.video || "",
          });

        
         
       
        
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setisModelLoader(false);
    }
  };

  const getFileNameFromURL = (url) => {
    if (typeof url !== "string") {
      console.error("Invalid URL:", url);
      return "";
    }
    const parts = url.split("/");
    // const filename = parts.pop();
    const filename = parts[parts.length - 1];

    return decodeURIComponent(filename);
  };


  const _deleteTopic = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/helpVideos/delete/${SelectTopicId}`);
        data = response.data;
      } else {
       
        const response = await Api.postRequest(`api/helpVideos/delete/${SelectTopicId}`);
        data = response.data;
      }
   
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getTopic(filter, search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectTopicId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error);
    }
  };


  const handelModelData = (name, value) => {
    setTopicQuestion((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    
    
   
  };

 







  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    _getTopic(filter, search, recordsPerPage, currentPage);
  }, [currentPage, recordsPerPage]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const applyFilter = () => {
    _getTopic(filter, search, recordsPerPage, currentPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setFilter({
      standard: "",
      semester: "",
      subject: "",
      chapter: "",
    });
  };

  useEffect(() => {
    if (isResetFilter) {
      _getTopic(filter, search, recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <div>
      <div className="h-full flex items-center justify-center ">
        <div className="h-full flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 w-full sm:px-6 ">
          <div className="flex-1 px-2 sm:px-0 w-full">
            <div>
              <div className="flex justify-between items-center mt-2">
                <div>
                  <MasterTitle
                    title="Help Video"
                    description="Below are the list of all the help videos."
                  />
                </div>
                <div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"Add Help"}
                        onClick={() => {
                          setTopicQuestion({});
                          setIsModel(true);
                          setModelType("add");
                        }}
                      />
                    )}
                  </div>
                  <Model
                    title={modelType === "add" ? "Add Help" : "Edit Help"}
                    show={isModel}
                    onCancel={() => {
                      setIsModel(false);
                      // setTopicQuestion({});
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setSelectedId("");
                    }}
                    onOk={() => {
                      handleAddModelSubmit();
                    }}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    width="max-w-3xl"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    {isModelLoader ? (
                      <tr
                        style={{
                          height: "120px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              label="Title"
                              id=""
                              name="School Type"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={topicQuestion.title || ""}
                              onChange={(e) =>
                                handelModelData("title", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.topic ? error?.topic : ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                        <div className="w-full flex flex-col">
                            <Input
                              label="Video Url"
                              id=""
                              name="Video Url"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={topicQuestion.video || ""}
                              onChange={(e) =>
                                handelModelData("video", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!topicQuestion?.topic ? error?.topic : ""}
                            </Typography>
                          </div>
                        </div>
                      </>
                    )}
                  </Model>
                </div>
              </div>
              <div className="sm:mb-0 overflow-hidden">
                <div className="flex flex-col mt-4">
                  <section
                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                    style={{
                      width: "100%",
                      height: "75vh",
                      margin: "0 auto",
                      padding: "20px",
                    }}
                  >
                    {" "}
                    <div className="flex justify-between items-center px-8 mb-3">
                      <div  style={{ display: "flex", gap:'5px' }}>
                      <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here..."
                        autoComplete="given-name"
                        required
                        className="w-72"
                        onChange={(e) => {
                          setSearch(e?.target?.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <Button
                          onClick={() => applyFilter()}
                          id=""
                          type="button"
                          // label={"SEARCH"}
                          searchicon={true}
                        />
                      </div>
                      <div>
                        <Records
                          recordsPerPage={recordsPerPage}
                          setCurrentPage={setCurrentPage}
                          setRecordsPerPage={setRecordsPerPage}
                          dataCount={dataCount}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "58vh",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {!isLoader && TopicData?.length <= 0 ? (
                        <NoDataFound />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#f4f7f8",
                              zIndex: 1,
                            }}
                          >
                            <tr
                              style={{
                                borderBottom: "1px solid #dddddd",
                                textAlign: "left",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#7B809A",
                                textTransform: "uppercase",
                                backgroundColor: "#f4f7f8",
                              }}
                            >
                              {" "}
                             
                              {titleData.map(
                                (v, ind) =>
                                  v?.title && (
                                    <th
                                      key={ind}
                                      // className="py-4 p-8"
                                      style={{
                                        padding: "12px",
                                        backgroundColor: "#f4f7f8",
                                        position:
                                          v.title === "EDIT" ||
                                          v.title === "DELETE"
                                            ? "sticky"
                                            : "",
                                        right:
                                          v.title === "EDIT"
                                            ? "68px"
                                            : v.title === "DELETE"
                                            ? "0"
                                            : "",
                                      }}
                                    >
                                      <div className="flex justify-between">
                                        <span>{v.title}</span>
                                        {v.title !== "EDIT" &&
                                          v.title !== "DELETE" && (
                                            <span>
                                              <ChevronUpDownIcon
                                                onClick={() => {
                                                  let res = SortData(
                                                    TopicData,
                                                    v.fieldName,
                                                    v.sort
                                                  );

                                                  let copyData = [...titleData];
                                                  copyData[ind].sort =
                                                    v.sort > 0 ? -1 : 1;
                                                  setTitleData(copyData);
                                                  sethelpData(res);
                                                }}
                                                className="w-5 h-5"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {isLoader ? (
                              <tr style={{ height: "25rem" }}>
                                <td
                                  colSpan={10}
                                  style={{ textAlign: "center" }}
                                >
                                  <CircularProgress />
                                </td>
                              </tr>
                            ) : (
                              TopicData.map((data, id) => (
                                <tr
                                  key={id}
                                  className="my-4 p-8 text-[#000] font-reguler text-sm font-medium"
                                >
                                  {/* <td className="px-6 py-3 whitespace-no-wrap">
                                    <Checkbox
                                      checked={checkedItems.includes(data.id)}
                                      onChange={() =>
                                        handleCheckboxChange(data.id)
                                      }
                                    />
                                  </td> */}
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.id}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.title}
                                  </td>
                                  <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                    {data.video}
                                  </td>
                                 
                                  {access?.edit && (
                                    <td
                                      className="relative whitespace-nowrap  p-8 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "68px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="edit"
                                        onClick={() => {
                                          setIsModel(true);
                                          setModelType("edit");
                                          setSelectedId(data.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                  {access?.delete && (
                                    <td
                                      className="relative whitespace-nowrap  p-6 py-2.5  text-left"
                                      style={{
                                        position: "sticky",
                                        right: "0",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <ActionButton
                                        key={data.key}
                                        action="delete"
                                        onClick={() => {
                                          setDeleteType("single");
                                          setOpenModel(true);
                                          setSelectTopicId(data?.id);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      data={TopicData}
                      numbers={numbers}
                      npage={npage}
                      lastIndex={lastIndex}
                      firstIndex={firstIndex}
                      dataCount={dataCount}
                    />
                  </section>
                </div>
                {/* Delete */}

                <DeletePopup
                  title={"Delete"}
                  show={openModel}
                  description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                  onCancel={() => {
                    setOpenModel(false);
                    setSelectTopicId("");
                  }}
                  onClose={() => {
                    setOpenModel(false);
                    setSelectTopicId("");
                  }}
                  onDelete={() => {
                    _deleteTopic();
                  }}
                  cancelButtonRef={cancelButtonRef}
                  okText="Delete"
                  cancelText="Cancel"
                />

                {/* Delete */}
                <FloatNotification
                  show={showFloatNotification}
                  title={"Successfully deleted!"}
                  type="success"
                  description={"Data Deleted."}
                  onClick={() => {
                    setShowFloatNotification(false);
                  }}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpVideoSection;
