import React, { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import MasterTitle from "./MasterTitle";
import { Input } from "./Input";
import Pagination from "./Pagination";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import DeletePopup from "./DeletePopup";
import FloatNotification from "./FloatNotification";
import { PlusIcon } from "@heroicons/react/20/solid";
import Model from "./Model";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";


import { Listbox } from "@headlessui/react";
import { SortData } from "./SortData";
import Records from "./Records";
import { useDebounce } from "../hooks/use-debounce";
import {
  ChevronUpDownIcon, EyeSlashIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import SelectBox from "./Select";
import moment from "moment";

const InstituteVacancy = (props) => {
  const access = {
    ...(props?.access?.find((e) => e?.name === "Job") ?? {}),
    preview: true,
  };
  const [titleData, setTitleData] = useState([
    {
      title: "JOB ID",
      sort: 1,
      fieldName: "id",
    },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "status",
    },
    {
      title: "STAFF",
      sort: 1,
      fieldName: "staff",
    },
    {
      title: "FULL NAME",
      sort: 1,
      fieldName: "full name",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "ABOUT",
      sort: 1,
      fieldName: "about",
    },
    {
      title: "ADDRESS",
      sort: 1,
      fieldName: "address",
    },
    {
      title: "CITY",
      sort: 1,
      fieldName: "city",
    },
    {
      title: "DESCRIPTION",
      sort: 1,
      fieldName: "description",
    },
    {
      title: "INSTITUTE NAME",
      sort: 1,
      fieldName: "institute",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "NUMBER",
      sort: 1,
      fieldName: "number",
    },

  ]);
  const [openModel, setOpenModel] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const [search, setSearch] = useState("");
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedOption, setSelectedOption] = useState({
    city: "",
    field: "",
    status: "",
  });
  const debouncedValue = useDebounce(search, 800);
  const [isLoader, setIsLoader] = useState(false);
  const [filter, setFilter] = useState({
    board: "",
    medium: "",
    standard: "",
    subject: "",
    semester: "",
  });

  const [isResetFilter, setIsResetFilter] = useState(false);
  const [boardOutData, setBoardOutData] = useState([]);
  const [mediumOutData, setMediumOutData] = useState([]);
  const [standardOutData, setStandardOutData] = useState([]);
  const [subjectOutData, setSubjectOutData] = useState([]);
  const [semesterOutData, setSemesterOutData] = useState([]);

  const getBoardOutData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumOutData = async () => {
    try {
      const { data } = await Api.getRequest(`api/medium?board=${filter?.board?.id || ""}`);
      const req = JSON.parse(data);
      setMediumOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const getStandardOutData = async (l) => {
    try {
      let body = `?medium=${filter?.medium?.id || ""}&board=${filter?.board?.id || ""}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterOutData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error);
    }
  };

  const getSubjectOutData = async () => {
    try {
      let body = `?standard=${filter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectOutData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardOutData();
  }, []);

  useEffect(() => {
    if (filter?.board) {
      getMediumOutData();
    }
  }, [filter?.board]);

  useEffect(() => {
    if (filter?.medium) {
      getStandardOutData();
    }
  }, [filter?.medium]);
  useEffect(() => {
    if (filter?.standard) {
      getSubjectOutData();
    }
  }, [filter?.standard]);


  const handleFilter = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });

    if (name === "board") {
      setFilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
      }));
      setMediumOutData([]);
      setStandardOutData([]);
      setSemesterOutData([]);
      setSubjectOutData([]);
    }

    if (name === "medium") {
      setFilter((oldValue) => ({
        ...oldValue,
        standard: "",
        subject: "",
        semester: "",
      }));
      setStandardOutData([]);
      setSubjectOutData([]);
      setSemesterOutData([]);
    }
    if (name === "standard") {
      setFilter((oldValue) => ({
        ...oldValue,
        subject: "",
        semester: "",
      }));
      setSubjectOutData([]);
      setSemesterOutData([]);
    }
    if (name === "semester") {
      setFilter((oldValue) => ({
        ...oldValue,
        subject: "",
      }));
      setSubjectOutData([]);
    }
  };

  const _getHistory = async (limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/jobRecruit/get?&selectField=${selectedOption?.field || ""}&status=approve&isActive=${selectedOption?.status || ""}&search=${search || ""}&limit=${limit || 50}&offset=${offset || 1
        }&board=${filter?.board?.id || ""
        }&medium=${filter?.medium?.id || ""
        }&standard=${filter?.standard?.id || ""
        }&semester=${filter?.semester?.id || ""
        }&subject=${filter?.subject?.id || ""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setHistoryData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      } else {
        ErrorToast(req?.message);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const toggleActiveStatus = async (data) => {
    const body = {
      isActive: !data?.isActive,
    };
    try {
      const response = await Api.putRequest(
        `api/jobRecruit/update/${data?.id}`,
        body
      );
      if (response?.statusCode === 200) {
        SuccessToast(response?.data?.message);
        _getHistory(recordsPerPage, currentPage);
      } else {
        ErrorToast(response?.data?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    _getHistory(recordsPerPage, currentPage);
  }, [currentPage, recordsPerPage]);

  const handleOptionChange = (e, name) => {
    const { value } = e.target;
    setSelectedOption((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const applyFilter = () => {
    setCurrentPage(1);
    _getHistory(recordsPerPage, currentPage);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setSelectedOption("");
    setFilter({
      standard: "",
      subject: "",
    });
    setSubjectOutData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getHistory(recordsPerPage, currentPage);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <div className="w-full sm:px-0">
      <div className="w-full sm:px-0 flex flex-col items-start gap-x-4 mr-4">

        <div className="w-full flex items-center flex-wrap sm:flex-nowrap gap-x-4">
          <div className="w-full mb-3">
            <SelectBox
              data={boardOutData || []}
              value={filter?.board}
              onChange={(e) => handleFilter("board", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Board"
              type="board"
              getBoard={getBoardOutData}
            />
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={mediumOutData || []}
              value={filter?.medium}
              onChange={(e) => handleFilter("medium", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Medium"
              type="medium"
              getMedium={getMediumOutData}
            />
          </div>
          <div className="w-full mb-3">
            <SelectBox
              data={standardOutData || []}
              value={filter?.standard}
              onChange={(e) => handleFilter("standard", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Standard"
              type={"standard"}
              getStandard={getStandardOutData}
            />
          </div>
          {/* <div className="w-full mb-3">
                        <SelectBox
                            data={semesterOutData || []}
                            value={filter?.semester}
                            onChange={(e) => handleFilter("semester", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Semester"
                            type={"semester"}
                            getSemester={getSemesterOutData}
                        />
                    </div> */}
          <div className="w-full mb-3">
            <SelectBox
              data={subjectOutData || []}
              value={filter?.subject}
              onChange={(e) => handleFilter("subject", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Subject"
              type={"subject"}
              getSubject={getSubjectOutData}
            />
          </div>
        </div>
        <div className="w-full flex mt-3 gap-x-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="w-full">
            <FormControl fullWidth>
              <Select
                size="small"
                value={selectedOption?.field || ""}
                labelId="select-placeholder"
                className="font-regular mb-3"
                onChange={(e) => handleOptionChange(e, "field")}
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" >
                  Field
                </MenuItem>
                <MenuItem value={"teaching"}>Teaching</MenuItem>
                <MenuItem value={"adminstaff"}>Admin Staff</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-full">
            <FormControl fullWidth>
              <Select
                size="small"
                value={selectedOption?.status || ""}
                labelId="select-placeholder"
                className="font-regular mb-3"
                onChange={(e) => handleOptionChange(e, "status")}
                displayEmpty
                sx={{
                  background: "white",
                  border: "solid 1px #E5F1EB",
                  mr: 1,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" >
                  Status
                </MenuItem>
                <MenuItem value={"1"}>Active</MenuItem>
                <MenuItem value={'0'}>Deactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="">
            <Button
              onClick={() => applyFilter()}
              id=""
              type="button"
              label={"SEARCH"}
            />
          </div>
          <div className="">
            <Button
              onClick={() => resetFilter()}
              id=""
              type="button"
              label={"RESET"}
            />
          </div>
        </div>


      </div>
      <div className="flex justify-between items-center mt-2">
        {/* <div>
          <MasterTitle
            title="Paper History"
            description="Below are the list of all the paper history."
          />
        </div> */}
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-1">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "5px",
            }}
          >
            {" "}
            <div className="flex justify-between items-center px-8 mb-3">
              <div style={{ display: "flex", gap: '5px' }}>
                <Input
                  id=""
                  name="Search"
                  type="text"
                  placeholder="Search here..."
                  autoComplete="given-name"
                  required
                  className="w-72"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setCurrentPage(1);
                  }}
                  value={search}
                />
                <Button
                  onClick={() => applyFilter()}
                  id=""
                  type="button"
                  // label={"SEARCH"}
                  searchicon={true}
                />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsPerPage={setRecordsPerPage}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: '80vh',
                height: '80vh',
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && historyData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {titleData.map(
                        (v, ind) =>
                          v?.title && (
                            <th
                              key={ind}
                              // className="py-4 p-8 "
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                              }}>
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {(
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          historyData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setHistoryData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={10} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      historyData.map((data, id) => (
                        <tr
                          key={id}
                          className="my-4 p-8 text-[#000] font-bold text-sm"
                          style={{
                            backgroundColor:
                              data?.isActive
                                ? "#8cfb8c"
                                : "#fb6868",
                          }}
                        >
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.id}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-6 py-2.5 ">
                            <box
                              onClick={() => toggleActiveStatus(data)}
                            >
                              <Switch
                                {...label}
                                checked={data?.isActive}
                              />
                            </box>
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.selectField}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.fullName}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.jobInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.about}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.address}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.city}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.description}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.instituteName}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.boardInfo?.board_name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.mediumInfo?.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.standards?.map((v) => (`${v?.name},`))}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.subjects?.map((v) => (`${v?.subject},`))}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.mobileNo}
                          </td>


                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={historyData}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
              dataCount={dataCount}
            />
          </section>
        </div>
        {/* Delete */}

        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          onDelete={() => {
            setOpenModel(false);
            setShowFloatNotification(true);
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />

        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default InstituteVacancy;
