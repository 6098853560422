import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Empty } from "antd";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Select(props) {
  const [hasScrolled, setHasScrolled] = useState(false);
  // const [selected, setSelected] = useState(props.data[0]);

  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelection = (item) => {
    if (item !== "") {
      const isSelected = selectedItems.includes(item);
      if (isSelected) {
        setSelectedItems((prevSelected) =>
          prevSelected.filter((selectedItem) => selectedItem !== item)
        );
      } else {
        setSelectedItems((prevSelected) => [...prevSelected, item]);
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     (props?.type === "qType" || props?.type === "standard") &&
  //     props?.multipleSelect
  //   ) {
  //     props?.getSelectedItems(selectedItems);
  //   }
  // }, [selectedItems]);

  // useEffect(() => {
  //   if (
  //     (props?.type === "qType" || props?.type === "medium") &&
  //     props?.multipleSelect
  //   ) {
  //     props?.getSelectedItems(selectedItems);
  //   }
  // }, [selectedItems]);

  // useEffect(() => {
  //   if (
  //     (props?.type === "qType" || props?.type === "board") &&
  //     props?.multipleSelect
  //   ) {
  //     props?.getSelectedItems(selectedItems);
  //   }
  // }, [selectedItems]);

  useEffect(() => {
    if (
      (props?.type === "qType" ||
        props?.type === "standard" ||
        props?.type === "reference") &&
      typeof props?.value === "string"
    ) {
      const valueArray =
        (props?.type === "qType" ||
          props?.type === "standard" ||
          props?.type === "reference") &&
        props?.value?.split(",").map((id) => parseInt(id.trim(), 10));
      const selectedData =
        (props?.type === "qType" ||
          props?.type === "standard" ||
          props?.type === "reference") &&
        props?.data.filter((item) => valueArray.includes(item.id));

      setSelectedItems(selectedData);
    }
  }, [props.value, props.data, props.type]);

  return (
    // <Listbox value={props.value} onChange={props.onChange}>
    <Listbox value={props.value} onChange={props.onChange} disabled={props.disabled}>
      {({ open }) => (
        <div>
          <Listbox.Label className="mb-3 block text-sm font-medium text-black">
            {props.label}
            {props.required && <span className="text-red-400"> *</span>}
          </Listbox.Label>
          <div className=" relative">
            <Listbox.Button
              {...props}
              className={`
              ${props.className ? props.className : ""}
              
              block text-left w-full appearance-none rounded-md border border-gray-200 bg-white px-3 py-2 text-[#000] sm:text-sm  hover:border-black`}
            >
              <span className="block truncate mr-auto text-black hover:border-blue-500 text-black">
                {props?.type === "standard" && props?.value?.name}
                {props?.type === "medium" && props?.value?.name}
                {props?.type === "position" && props?.value?.name}
                {props?.type === "semester" && props?.value?.semester}
                {props?.type === "discountType" && props?.value?.discountType}
                {props?.type === "subject" && props?.value?.subject}
                {props?.type === "chapter" && props?.value?.chapter}
                {props?.type === "assignPartner" && props?.value?.username}
                {props?.type === "board" && props?.value?.board_name}
                {props?.type === "reference" && props?.value?.book_name}
                {props?.type === "qType" && props?.value?.name}
                {props?.type === "PaperGenerateBanners" &&
                 ( props?.value?.name ||
                  props?.value)}
                {props?.type === "book" && props?.value?.name}
                {props?.type === "mediumName" && props?.value}
                {/* {props?.type === "thisYear" && props?.value?.name} */}
                {props?.type === "thisYear" && props?.value?.name}
                {props?.type === "totalMarks" &&
                  (props?.value?.name || props?.value)}
                {props?.type === "TrueFalse" &&
                  (props?.value?.name || props?.value)}
                {props?.type === "status" && props?.value?.name}
                {props?.type === "version" && props?.value?.name}
                {props.placeholder && !props?.value && props.placeholder}
                {!props.placeholder && "Select"}
                {/* {props?.value?.name ??
                  props?.value?.chapter ??
                  props?.value?.semester ??
                  props?.value?.subject ??
                  props?.value?.StandardInfo?.name ??
                  props.placeholder ??
                  "Select"} */}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="h-5 w-5 text-black"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                onScroll={(event) => {
                  event.stopPropagation();
                  const listboxNode = event.currentTarget;
                  const savedScrollTop = listboxNode.scrollTop;
                  const diff = Math.round(
                    listboxNode.scrollHeight - savedScrollTop
                  );
                  if (!hasScrolled && diff - 25 <= listboxNode.clientHeight) {
                    const returnScrollTop = () => {
                      setTimeout(() => {
                        listboxNode.scrollTop = savedScrollTop;
                        setHasScrolled(false);
                      }, 100);
                      setHasScrolled(true);
                      if (props?.type === "standard") {
                        props?.getStandard(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      } else if (props?.type === "medium") {
                        props?.getMedium(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      } else if (props?.type === "position") {
                        props?.getPosition(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      }else if (props?.type === "board") {
                        props?.getBoard(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      } else if (props?.type === "thisYear") {
                        props?.getYear(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      } else if (props?.type === "subject") {
                        props?.getSubject(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      } else if (props?.type === "semester") {
                        props?.getSemester(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      } else if (props?.type === "chapter") {
                        props?.getChapter(
                          0 + props?.isPage ? 0 : props?.value.lengh
                        );
                      } else if (props?.type === "assignPartner") {
                        props?.getPartner(
                          0 + props?.isPage ? 0 : props?.value.lengh
                        );
                      } else if (props?.type === "qType") {
                        props?.getQType(0);
                      } else if (props?.type === "reference") {
                        props?.getRreference(
                          0 + props?.isPage ? 0 : props?.value.lengh
                        );
                      } else if (props?.type === "book") {
                        props?.getBook(0);
                      } else if (props?.type === "board") {
                        props?.getBoard();
                      } else if (props?.type === "mediumName") {
                        props?.getMedium(
                          0 + props?.isPage ? 0 : props?.value?.lengh
                        );
                      }
                    };

                    returnScrollTop();
                  }
                }}
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {!props?.isthisYear && (
                  <Listbox.Option
                    value=""
                    onClick={() => toggleSelection("")}
                    className={({ active }) =>
                      classNames(
                        active ? "text-black bg-white" : "text-gray-500",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                  >
                    {props?.placeholder}
                  </Listbox.Option>
                )}
                {props?.data?.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    // value={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-black bg-white hover:border-1 font-bold border-black border-1" : "text-black hover:border-1 hover:border-sky-600 font-medium hover:text-sky-600",
                        "cursor-pointer select-none relative py-2 pl-3 pr-9  hover:text-sky-600 hover:font-bold"
                      )
                    }
                    value={person}
                    name={person.id}
                    onClick={() => toggleSelection(person)}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            props.value === person
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {props?.type === "standard" && person?.name}
                          {props?.type === "discountType" && person?.name}
                          {props?.type === "medium" && person?.name}
                          {props?.type === "position" && person?.name}
                          {props?.type === "board" && person?.name}
                          {props?.type === "semester" && person?.semester}
                          {props?.type === "subject" && person?.subject}
                          {props?.type === "chapter" && person?.chapter}
                          {props?.type === "assignPartner" && person?.username}
                          {props?.type === "qType" && person?.name}
                          {props?.type === "reference" && person?.book_name}
                          {props?.type === "PaperGenerateBanners" &&
                            person?.name}
                          {props?.type === "book" && person?.name}
                          {props?.type === "TrueFalse" && person?.name}
                          {props?.type === "board" && person?.board_name}
                          {props?.type === "mediumName" && person?.name}
                          {props?.type === "totalMarks" && person?.name}
                          {props?.type === "thisYear" && person?.name}
                          {props?.type === "version" && person?.name}
                          {props?.type === "status" && person?.name}
                          {!props?.type && person?.name}
                          {/* {person?.name ||
                            person?.chapter ||
                            person?.semester ||
                            person?.subject ||
                            person?.StandardInfo.name} */}
                          {/* || person.StandardInfo.name */}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-gray-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
