import React, { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Model from "./Model";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import Select from "./Select";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { useDebounce } from "../hooks/use-debounce";
import { Autocomplete, Chip, FormControl, MenuItem, TextField, TypographyM, Select as MSelect, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { config } from "../Api/config";
import MultiSelect from "./MultiSelect";

const JobRequest = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Job")?.[0];
  console.log(access, "reqmoduleaccess")
  const [isModel, setIsModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 800);
  const [isLoader, setIsLoader] = useState(false);
  const [isModelLoader, setisModelLoader] = useState(false);
  const [papergenfilter, setpapergenfilter] = useState({
    board: "",
    medium: "",
    standard: "",
    subject: "",
    field: "",
  });
  const [modelPaperGenerationData, SetModelPaperGenerationData] = useState({
    board: "",
    medium: "",
    standard: [],
    subject: [],
    name: "",
    position: "",
    about: "",
    address: "",
    city: "",
    degree: "",
    experience: "",
    number: "",
    field: "",
    status: "",
  });
  console.log(modelPaperGenerationData, "FFFFFFFFFFFmodelPaperGenerationData");
  const [boardData, setBoardData] = useState([]);
  const [mediumData, setMediumData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  console.log(selectedFile, "selectedFile");
  const [selectionDataBoard, setSelectionDataBoard] = useState([]);
  const [selectionDataMedium, setSelectionDataMedium] = useState([]);
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [selectionDataSubject, setSelectionDataSubject] = useState([]);
  const [selectionDataPosition , setSelectionDataPosition] = useState([]);
  const [selectionDataSemester, setSelectionDataSemester] = useState([]);
  const [qTypeModalData, setQTypeModalData] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [SelectAutoPaperGenerationId, setSelectAutoPaperGenerationId] =
    useState();
  const cancelButtonRef = useRef(null);
  const [titleData, setTitleData] = useState([
    {
      title: "JOB ID",
      sort: 1,
      fieldName: "id",
    },
    {
      title: "FULL NAME",
      sort: 1,
      fieldName: "full name",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "ABOUT",
      sort: 1,
      fieldName: "about",
    },
    {
      title: "ADDRESS",
      sort: 1,
      fieldName: "address",
    },
    {
      title: "CITY",
      sort: 1,
      fieldName: "city",
    },
    {
      title: "DEGREE",
      sort: 1,
      fieldName: "degree",
    },
    {
      title: "EXPERIENCE",
      sort: 1,
      fieldName: "experience",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "subject",
    },
    {
      title: "NUMBER",
      sort: 1,
      fieldName: "number",
    },
    {
      title: "RESUME",
      sort: 1,
      fieldName: "resume",
    },
    {
      title: "POSTED BY",
      sort: 1,
      fieldName: "postedBy",
    },
    {
      title: "STAFF",
      sort: 1,
      fieldName: "staff",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);
  const TotalMarksData = [
    { id: 1, name: "25" },
    { id: 2, name: "30" },
    { id: 3, name: "35" },
    { id: 4, name: "40" },
    { id: 5, name: "50" },
    { id: 6, name: "80" },
    { id: 7, name: "100" },
  ];
  const [error, setError] = useState({});
  console.log(error, "FFFFFFFFFFFFFFFFerror");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState(0);
  const [PaperGenerationData, setPaperGenerationData] = useState([]);
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetFilter, setIsResetFilter] = useState(false);

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setStandardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSemesterData(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSubjectData = async () => {
    try {
      let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}`;
      const { data } = await Api.getRequest(`api/subject${body}`);
      const req = JSON.parse(data);
      setSubjectData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (papergenfilter["board"] !== "") {
      getMediumData();
    }
  }, [papergenfilter["board"]]);

  useEffect(() => {
    if (papergenfilter["medium"] !== "") {
      getStandardData();
    }
  }, [papergenfilter["medium"]]);

  useEffect(() => {
    if (papergenfilter["standard"] !== "") {
      getSubjectData();
    }
  }, [papergenfilter["standard"]]);


  const handleFilter = (name, value) => {
    console.log(name, value, "namevlaue");
    setpapergenfilter({
      ...papergenfilter,
      [name]: value,
    });
    if (name === "board") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setMediumData([]);
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "medium") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        subject: "",
        [name]: value,
      }));
      setStandardData([]);
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "standard") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        semester: "",
        subject: "",
        [name]: value,
      }));
      setSemesterData([]);
      setSubjectData([]);
    }
    if (name === "semester") {
      setpapergenfilter((oldValue) => ({
        ...oldValue,
        subject: "",
        [name]: value,
      }));
      setSubjectData([]);
    }
  };

  const getBoardModelData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setSelectionDataBoard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumModelData = async () => {
    try {
      let body = `?board=${modelPaperGenerationData?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setSelectionDataMedium(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardModelData = async () => {
    try {
      let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  // const getSemesterModelData = async () => {
  //   try {
  //     let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}&standard=${modelPaperGenerationData?.standard?.id}`;
  //     const { data } = await Api.getRequest(`api/semester${body}`);
  //     const req = JSON.parse(data);
  //     setSelectionDataSemester(req?.data?.rows);
  //   } catch (error) {
  //     ErrorToast(error?.message);
  //   }
  // };

  const getSubjectModelData = async () => {
    try {
      let body = `?standard=${JSON.stringify(modelPaperGenerationData?.standard?.map((item) => item?.id))}`;
      const { data } = await Api.getRequest(`api/subject/mobile/subject-list${body}`);
      const req = JSON.parse(data);
      setSelectionDataSubject(req?.data || []);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getPositionModelData = async () => {
    try {
      const { data } = await Api.getRequest(`api/jobPosition/getAll`);
      const req = JSON.parse(data);
      setSelectionDataPosition(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  }
  useEffect(() => {
    getBoardData();
    getBoardModelData();
    getQTypeModalData();
    getPositionModelData();
  }, []);

  useEffect(() => {
    if (modelPaperGenerationData["board"] != "") {
      getMediumModelData();
    }
    if (modelPaperGenerationData["medium"] != "") {
      getStandardModelData();
    }
    if (modelPaperGenerationData["standard"]?.length != 0) {
      getSubjectModelData();
    }
  }, [modelPaperGenerationData]);

  const handelModelData = (name, value) => {
    if(name === "number" && value.length > 10){return}
    SetModelPaperGenerationData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
    if (name === "board") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: [],
        subject: [],
        [name]: value,
      }));
      setSelectionDataMedium([]);
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "medium") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        standard: [],
        subject: [],
        [name]: value,
      }));
      setSelectionDataStandard([]);
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "standard") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        subject: [],
        [name]: value,
      }));
      setSelectionDataSemester([]);
      setSelectionDataSubject([]);
    }
    if (name === "semester") {
      SetModelPaperGenerationData((oldValue) => ({
        ...oldValue,
        subject: [],
        [name]: value,
      }));
      setSelectionDataSubject([]);
    }
    if (name === "field") {
      if (value == "teaching") {
        SetModelPaperGenerationData((oldValue) => ({
          ...oldValue,
          position: "",
          [name]: value,
        }));
      }
      if (value == "adminstaff") {
        SetModelPaperGenerationData((oldValue) => ({
          ...oldValue,
          board: "",
          medium: "",
          standard: [],
          subject: [],
          [name]: value,
        }));
        setSelectionDataMedium([]);
        setSelectionDataStandard([]);
        setSelectionDataSubject([]);
      }
    }
  };

  const getQTypeModalData = async () => {
    try {
      const { data } = await Api.getRequest(`api/qtype`);
      const req = JSON.parse(data);
      setQTypeModalData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);

    const response = await fetch(
      config.baseURL + "api/upload/single_file",
      {
        method: "POST",
        // headers: {
        //   Authorization: "Bearer " + token,
        // },
        body: formData,
      }
    );
    const result = await response.json();

    // setImage(result?.data);
    setSelectedFile(result?.data);
  };

  const _getById = async (id) => {
    try {
      if (id) {
        setisModelLoader(true);
        const { data } = await Api.getRequest(
          `api/job/getById/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);
          SetModelPaperGenerationData({
            board: req?.data?.boardInfo || "",
            medium: req?.data?.mediumInfo || "",
            standard: req?.data?.standards || [],
            subject: req?.data?.subjects || [],
            name: req?.data?.fullName || "",
            position: req?.data?.jobInfo || "",
            about: req?.data?.about || "",
            address: req?.data?.address || "",
            city: req?.data?.city || "",
            degree: req?.data?.degree || "",
            experience: req?.data?.experience || "",
            number: req?.data?.mobileNo || "",
            field: req?.data?.selectField || "",
            status: req?.data?.status,
          });
          setSelectedFile(req?.data?.resume);

          if (req?.data?.standardInfo?.mediumInfo) {
            getMediumModelData();
          }
          if (req?.data?.standards) {
            getStandardModelData();
          }
          if (req?.data?.subjects) {
            getSubjectModelData();
          }

        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!modelPaperGenerationData?.board && modelPaperGenerationData?.field == "teaching") {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!modelPaperGenerationData?.medium && modelPaperGenerationData?.field == "teaching") {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (modelPaperGenerationData?.standard.length == 0 && modelPaperGenerationData?.field == "teaching") {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (modelPaperGenerationData?.subject.length == 0 && modelPaperGenerationData?.field == "teaching") {
      formIsValid = false;
      errors["subject"] = "Please select subject.";
    }
    if (!modelPaperGenerationData?.position && modelPaperGenerationData?.field == "adminstaff") {
      formIsValid = false;
      errors["position"] = "Please select position.";
    }
    if (!modelPaperGenerationData?.name) {
      formIsValid = false;
      errors["name"] = "Please enter name.";
    }
    if (!modelPaperGenerationData?.about) {
      formIsValid = false;
      errors["about"] = "Please enter about.";
    }
    if (!modelPaperGenerationData?.city) {
      formIsValid = false;
      errors["city"] = "Please enter city.";
    }
    if (!modelPaperGenerationData?.degree) {
      formIsValid = false;
      errors["degree"] = "Please enter degree.";
    }
    if (!modelPaperGenerationData?.experience) {
      formIsValid = false;
      errors["experience"] = "Please enter experience.";
    }
    if (!modelPaperGenerationData?.number) {
      formIsValid = false;
      errors["number"] = "Please enter number.";
    }
    if (!modelPaperGenerationData?.field) {
      formIsValid = false;
      errors["field"] = "Please enter field.";
    }
    if (!modelPaperGenerationData?.status) {
      formIsValid = false;
      errors["status"] = "Please enter status";
    }
    setError(errors);
    return formIsValid;
  };


  const _getAutoPaperGenerationData = async (limit, offset, papergenfilter) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/job/get?limit=${limit || 10}&offset=${search ? 1 : offset || 1
        }&status=pending&search=${search || ""}&board=${papergenfilter?.board?.id || ""
        }&medium=${papergenfilter?.medium?.id || ""}&standard=${papergenfilter?.standard?.id || ""
        }&semester=${papergenfilter?.semester?.id || ""}&subject=${papergenfilter?.subject?.id || ""
        }&selectField=${papergenfilter?.field || ""}`
      );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        setPaperGenerationData(req?.data);
        setDataCount(req?.totalCount);
        setIsLoader(false);
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleAddModelSubmit = async () => {
    if (validation()) {
      setIsLoading(true);
      if (modelType === "edit") {
        try {
          let body = {
            board_id: modelPaperGenerationData?.board?.id,
            medium_id : modelPaperGenerationData?.medium?.id,
            standard_id : modelPaperGenerationData?.standard?.map((item)=>item.id), 
            subject_id : modelPaperGenerationData?.subject?.map((item)=>item.id),
            fullName : modelPaperGenerationData?.name,
            jobposition_id : modelPaperGenerationData?.position?.id,
            about : modelPaperGenerationData?.about,
            address : modelPaperGenerationData?.address,
            city : modelPaperGenerationData?.city,
            degree : modelPaperGenerationData?.degree,
            experience : modelPaperGenerationData?.experience,  
            mobileNo : modelPaperGenerationData?.number,
            selectField : modelPaperGenerationData?.field,
            "status" : modelPaperGenerationData?.status,
            resume : selectedFile,
          };
          const res = await Api.putRequest(
            `api/job/update/${selectedId}`,
            body
          );
          if (res?.data?.status === 200) {
            SuccessToast(res?.data?.message);
            _getAutoPaperGenerationData(
              recordsPerPage,
              currentPage,
              papergenfilter
            );
            SetModelPaperGenerationData({});
            setIsModel(false);
            setValue([]);
            setSelectedId("");
            setSelectionDataMedium([]);
            setSelectionDataStandard([]);
            setSelectionDataSubject([]);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            ErrorToast(res?.data);
          }
        } catch (error) {
          ErrorToast(error?.message);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const _deletePaperGeneration = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(
          `api/job/delete`,
          body
        );
        data = response.data;
      } else {
        const body = {
          ids: [SelectAutoPaperGenerationId],
        };
        const response = await Api.postRequest(
          `api/job/delete`,
          body
        );
        data = response.data;
      }
      // const { data } = await Api.postRequest(
      //   `api/auto-paper-generation/delete/${SelectAutoPaperGenerationId}`
      // );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        _getAutoPaperGenerationData(
          recordsPerPage,
          currentPage,
          papergenfilter
        );
        setOpenModel(false);
        setSelectAutoPaperGenerationId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
  }, [recordsPerPage, currentPage]);

  const handleSelectedItems = (selectedItems) => {
    const Array = selectedItems.map((a) => a.id);
    const selectedItemsString = Array.join(", ");
    SetModelPaperGenerationData((oldValue) => ({
      ...oldValue,
      questionType: selectedItemsString,
    }));
  };

  useEffect(() => {
    handleSelectedItems(selectedItems);
  }, [selectedItems]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  const [value, setValue] = useState([]);
  const [values, setValues] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const idsString = newValue.map((option) => parseInt(option.id)).join(",");
    SetModelPaperGenerationData((oldValue) => ({
      ...oldValue,
      questionType: idsString,
    }));
  };

  useEffect(() => {
    if (modelPaperGenerationData?.questionType) {
      let qtypeIds;
      if (typeof modelPaperGenerationData.questionType === "string") {
        qtypeIds = modelPaperGenerationData.questionType.split(",").map(Number);
      } else if (Array.isArray(modelPaperGenerationData.questionType)) {
        qtypeIds = modelPaperGenerationData.questionType.map(Number);
      } else {
        console.error(
          "Unsupported type for questionType:",
          modelPaperGenerationData.questionType
        );
        return;
      }
      const selectedOptions = qTypeModalData.filter((option) =>
        qtypeIds.includes(option.id)
      );
      setValue(selectedOptions);
    }
  }, [modelPaperGenerationData, qTypeModalData]);

  const applyFilter = () => {
    _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
  };

  const resetFilter = () => {
    setIsResetFilter(true);
    setpapergenfilter({
      board: "",
      medium: "",
      standard: "",
      semester: "",
      subject: "",
    });
    setMediumData([]);
    setStandardData([]);
    setSemesterData([]);
    setSubjectData([]);
  };

  useEffect(() => {
    if (isResetFilter) {
      _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
      setIsResetFilter(false);
    }
  }, [isResetFilter]);

  return (
    <>
      <div className=" w-full sm:px-1 ">
        <div className="w-full sm:px-0 flex items-start gap-x-4 mr-4" style={{ display: "flex", alignItems: "end" }}>
          <div className="w-full">
            <FormControl fullWidth >
              <MSelect
                size="small"
                value={papergenfilter?.field || ""}
                labelId="select-placeholder"
                className="font-regular mb-3"
                onChange={(e) => handleFilter("field", e.target.value)}
                displayEmpty
                sx={{
                  border: "none",
                  background: "white",
                  mb: 0,
                  height: "37px",
                  borderColor: "gray",
                  color: "gray",
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E5F1EB",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="" >
                  Field
                </MenuItem>
                <MenuItem value={"teaching"}>Teaching</MenuItem>
                <MenuItem value={"adminstaff"}>Admin Staff</MenuItem>
              </MSelect>
            </FormControl>
          </div>
          <div className="w-full">
            <Select
              data={boardData || []}
              value={papergenfilter?.board}
              onChange={(e) => handleFilter("board", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Board"
              type="board"
              getBoard={getBoardData}
            />
          </div>
          <div className="w-full">
            <Select
              data={mediumData || []}
              value={papergenfilter?.medium}
              onChange={(e) => handleFilter("medium", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Medium"
              type="medium"
              getMedium={getMediumData}
            />
          </div>
          <div className="w-full">
            <Select
              data={standardData || []}
              value={papergenfilter?.standard}
              onChange={(e) => handleFilter("standard", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Standard"
              type={"standard"}
              getStandard={getStandardData}
            />
          </div>
          {/* <div className="w-full">
            <Select
              data={semesterData || []}
              value={papergenfilter?.semester}
              onChange={(e) => handleFilter("semester", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Semester"
              type={"semester"}
              getSemester={getSemesterData}
            />
          </div> */}
          <div className="w-full">
            <Select
              data={subjectData || []}
              value={papergenfilter?.subject}
              onChange={(e) => handleFilter("subject", e)}
              className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
              placeholder="All Subject"
              type={"subject"}
              getSubject={getSubjectData}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={() => applyFilter()}
              id="filterbtn"
              type="button"
              label={"SEARCH"}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={() => resetFilter()}
              id=""
              type="button"
              label={"RESET"}
            />
          </div>
        </div>
        <div className="flex-1 px-2 sm:px-0 w-full">
          <div>
            <div className="flex justify-between items-center mt-2">
              <div>
                <MasterTitle
                  title="Job Request"
                  description="Below are the list of all the job request."
                />
              </div>
              <div className="flex items-center space-x-2 overflow-x-auto">
                <div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {access?.delete && (
                      <Button
                        id=""
                        type="submit"
                        icon={TrashIcon}
                        label={"DELETE JOB REQUEST"}
                        onClick={() => {
                          setDeleteType("multi");
                          if (checkedItems.length > 0) {
                            setOpenModel(true);
                          }
                        }}
                      />
                    )}
                    {/* {access?.add && (
                      <Button
                        id=""
                        type="submit"
                        icon={PlusIcon}
                        label={"ADD JOB REQUEST"}
                        onClick={() => {
                          setIsModel(true);
                          setModelType("add");
                        }}
                      />
                    )} */}
                  </div>
                  <Model
                    title={
                      modelType === "add"
                        ? "Add job request"
                        : "Edit job request"
                    }
                    show={isModel}
                    onCancel={() => {
                      // SetModelPaperGenerationData({});
                      setIsModel(false);
                      setValue([]);
                      setError({});
                    }}
                    onClose={() => {
                      setIsModel(false);
                      setValue([]);
                      setError({});
                      SetModelPaperGenerationData({});
                      setSelectedId("");
                      setSelectionDataMedium([]);
                      setSelectionDataStandard([]);
                      setSelectionDataSemester([]);
                      setSelectionDataSubject([]);
                    }}
                    onOk={() => {
                      handleAddModelSubmit();
                    }}
                    okText="SUBMIT"
                    cancelText="CLOSE"
                    isLoading={isLoading}
                    disabled={isLoading}
                    width="max-w-3xl"
                  >
                    {isModelLoader ? (
                      <tr
                        style={{
                          height: "120px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      <>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                          <div className="w-full">
                            <form className="mb-2">
                              <label
                                htmlFor="fileUpload"
                                className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                              >
                                Resume
                              </label>
                              <input
                                type="file"
                                id="fileUpload"
                                class="hidden"
                                onChange={handleImageChange}
                              ></input>
                              <div className="flex justify-between">
                                <div className="flex items-center ">
                                  <button
                                    id=""
                                    type="button"
                                    label={"Choose file"}
                                    onClick={handleChooseFileClick}
                                    className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                                  >
                                    Choose file
                                  </button>
                                </div>
                                {selectedFile && (
                                  <div className="flex-shrink-0 w-50 h-10 ">
                                    <a href={selectedFile} target="_blank" >
                                      <img
                                        className="w-full h-full"
                                        src="https://cdn-icons-png.freepik.com/512/15147/15147661.png"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </form>
                            <Typography
                              className="my-2"
                              variant="caption"
                              style={{ color: "#d32f2f" }}
                            >
                              {!selectedFile ? error?.image : ""}
                            </Typography>
                          </div>
                        </div>
                        {modelPaperGenerationData?.field == "teaching" && <>
                          <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6">
                            <div className="w-full">
                              <Select
                                value={modelPaperGenerationData?.board}
                                data={selectionDataBoard || []}
                                onChange={(e) => handelModelData("board", e)}
                                className="font-regular mb-3"
                                label="Board"
                                required
                                placeholder="All Board"
                                type={"board"}
                                getBoard={getBoardModelData}
                                isPage={true}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!modelPaperGenerationData?.board
                                  ? error?.board
                                  : ""}
                              </Typography>
                            </div>
                            <div className="w-full">
                              <Select
                                value={modelPaperGenerationData?.medium}
                                data={selectionDataMedium || []}
                                onChange={(e) => handelModelData("medium", e)}
                                className="font-regular mb-3"
                                label="Medium"
                                required
                                placeholder="All Medium"
                                type={"medium"}
                                getMedium={getMediumModelData}
                                isPage={true}
                              />
                              <Typography variant="caption" color={"error"}>
                                {!modelPaperGenerationData?.medium
                                  ? error?.medium
                                  : ""}
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                            <div className="w-full">
                              <MultiSelect
                                label="Standard"
                                required
                                optionKey="name"
                                placeholder="Select Standard"
                                options={selectionDataStandard}
                                value={modelPaperGenerationData?.standard || []}
                                onChange={(e) => handelModelData("standard", e)}
                                error={error?.standard}
                              />
                            </div>
                            <div className="w-full">
                              <MultiSelect
                                label="Subject"
                                required
                                optionKey="subject"
                                optionKey1="id"
                                placeholder="Select Subject"
                                options={selectionDataSubject}
                                value={modelPaperGenerationData?.subject || []}
                                onChange={(e) => handelModelData("subject", e)}
                                error={error?.subject}
                              />
                            </div>
                          </div>
                        </>}

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col mb-3">
                            <Input
                              placeholder="Enter name"
                              label="Name"
                              id=""
                              name="name"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full "
                              value={
                                modelPaperGenerationData.name || ""
                              }
                              onChange={(e) =>
                                handelModelData("name", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.name
                                ? error?.name
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                             <Select
                                value={modelPaperGenerationData?.position}
                                data={selectionDataPosition || []}
                                disabled={modelPaperGenerationData?.field == "teaching"}
                                onChange={(e) => handelModelData("position", e)}
                                className="font-regular mb-3"
                                label="position"
                                required
                                placeholder="All position"
                                type={"position"}
                                getPosition={getPositionModelData}
                                isPage={true}
                              />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.position
                                ? error?.position
                                : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder="Enter About"
                              label="About"
                              id=""
                              name="about"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full "
                              value={
                                modelPaperGenerationData.about || ""
                              }
                              onChange={(e) =>
                                handelModelData("about", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.about
                                ? error?.about
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder=" Enter Address"
                              label="Address"
                              id=""
                              name="address"
                              type="text"
                              autoComplete="given-name"
                              className="w-full"
                              value={
                                modelPaperGenerationData.address || ""
                              }
                              onChange={(e) =>
                                handelModelData("address", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.address
                                ? error?.address
                                : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder=" Enter City"
                              label="City"
                              id=""
                              name="city"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full "
                              value={
                                modelPaperGenerationData.city || ""
                              }
                              onChange={(e) =>
                                handelModelData("city", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.city
                                ? error?.city
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder=" Enter Degree"
                              label="Degree"
                              id=""
                              name="degree"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={
                                modelPaperGenerationData.degree || ""
                              }
                              onChange={(e) =>
                                handelModelData("degree", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.degree
                                ? error?.degree
                                : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder=" Enter Experience"
                              label="Experience"
                              id=""
                              name="experience"
                              type="text"
                              autoComplete="given-name"
                              required
                              className="w-full "
                              value={
                                modelPaperGenerationData.experience || ""
                              }
                              onChange={(e) =>
                                handelModelData("experience", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.experience
                                ? error?.experience
                                : ""}
                            </Typography>
                          </div>
                          <div className="w-full flex flex-col">
                            <Input
                              placeholder=" Enter Phone Number"
                              label="Phone Number"
                              id=""
                              name="number"
                              type="number"
                              autoComplete="given-name"
                              required
                              className="w-full"
                              value={
                                modelPaperGenerationData.number || ""
                              }
                              onChange={(e) =>
                                handelModelData("number", e.target.value)
                              }
                            />
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.number
                                ? error?.number
                                : ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                          <div className="w-full flex flex-col">
                            <div className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">Staff <span className="text-red-400">*</span></div>

                            <FormControl fullWidth >
                              <MSelect
                                size="small"
                                value={modelPaperGenerationData?.field || ""}
                                labelId="select-placeholder"
                                className="font-regular mb-3"
                                onChange={(e) => handelModelData("field", e.target.value)}
                                displayEmpty
                                sx={{
                                  border: "none",
                                  background: "white",
                                  mb: 0,
                                  height: "37px",
                                  borderColor: "gray",
                                  color: "gray",
                                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #E5F1EB",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                <MenuItem value="" >
                                  Field
                                </MenuItem>
                                <MenuItem value={"teaching"}>Teaching</MenuItem>
                                <MenuItem value={"adminstaff"}>Admin Staff</MenuItem>
                              </MSelect>
                            </FormControl>
                            <Typography variant="caption" color={"error"}>
                              {!modelPaperGenerationData?.field
                                ? error?.field
                                : ""}
                            </Typography>
                          </div>

                          <div className="w-full flex flex-col">
                            <FormControl>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{
                                  fontFamily: "poppins",
                                  color: "#252F40",
                                  opacity: "1",
                                }}
                              >
                                Status
                                <span className="text-red-400">*</span>
                              </FormLabel>
                              <RadioGroup
                                row
                                required
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => handelModelData("status", e.target.value)}
                                value={modelPaperGenerationData.status}
                              >
                                <FormControlLabel
                                  value={"pending"}
                                  control={<Radio />}
                                  label="Pending"
                                />
                                <FormControlLabel
                                  value={"approve"}
                                  control={<Radio />}
                                  label="Approve"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </>
                    )}
                  </Model>
                </div>
              </div>
            </div>

            <div className="sm:mb-0 overflow-hidden">
              <div className="flex flex-col mt-2">
                <section
                  className="bg-white rounded-2xl flex flex-col shadow-sm "
                  style={{
                    width: "100%",
                    height: "75vh",
                    margin: "0 auto",
                    padding: "20px",
                  }}
                >
                  {" "}
                  <div className="flex justify-between items-center mt-3 px-8 mb-2">
                    <div style={{ display: "flex", gap: '5px' }}>
                      <Input
                        id=""
                        name="Search"
                        type="text"
                        placeholder="Search here..."
                        autoComplete="given-name"
                        required
                        className="w-72"
                        onChange={(e) => {
                          setSearch(e?.target?.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <Button
                        onClick={() => applyFilter()}
                        id=""
                        type="button"
                        // label={"SEARCH"}
                        searchicon={true}
                      />
                    </div>
                    <Records
                      setCurrentPage={setCurrentPage}
                      recordsPerPage={recordsPerPage}
                      setRecordsPerPage={setRecordsPerPage}
                      dataCount={dataCount}
                    />
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "58vh",
                      height: "60vh",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {!isLoader && PaperGenerationData?.length <= 0 ? (
                      <NoDataFound />
                    ) : (
                      <table style={{ width: "100%" }}>
                        <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#f4f7f8",
                            zIndex: 1,
                          }}
                        >
                          <tr
                            style={{
                              borderBottom: "1px solid #dddddd",
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#7B809A",
                              textTransform: "uppercase",
                              backgroundColor: "#f4f7f8",
                            }}
                          >
                            {" "}
                            <th
                              scope="col"
                              style={{ width: "3%", padding: "10px" }}
                            ></th>
                            {titleData.map(
                              (v, ind) =>
                                v?.title && (
                                  <th
                                    key={ind}
                                    style={{
                                      padding: "12px",
                                      backgroundColor: "#f4f7f8",
                                      position:
                                        v.title === "EDIT" ||
                                          v.title === "DELETE"
                                          ? "sticky"
                                          : "",
                                      right:
                                        v.title === "EDIT"
                                          ? "68px"
                                          : v.title === "DELETE"
                                            ? "0"
                                            : "",
                                    }}
                                  >
                                    <div className="flex justify-between">
                                      <span>{v.title}</span>
                                      {v.title !== "EDIT" &&
                                        v.title !== "DELETE" && (
                                          <span>
                                            <ChevronUpDownIcon
                                              onClick={() => {
                                                let res = SortData(
                                                  PaperGenerationData,
                                                  v.fieldName,
                                                  v.sort
                                                );

                                                let copyData = [...titleData];
                                                copyData[ind].sort =
                                                  v.sort > 0 ? -1 : 1;
                                                setTitleData(copyData);
                                                setPaperGenerationData(res);
                                              }}
                                              className="w-5 h-5"
                                            />
                                          </span>
                                        )}
                                    </div>
                                  </th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {isLoader ? (
                            <tr style={{ height: "25rem" }}>
                              <td colSpan={9} style={{ textAlign: "center" }}>
                                <CircularProgress />
                              </td>
                            </tr>
                          ) : (
                            PaperGenerationData?.map((data, id) => (
                              <tr
                                key={id}
                                className="my-3 px-3 text-[#000] font-bold text-[13px]"
                              >
                                <td className="px-6 py-2.5 whitespace-no-wrap">
                                  <Checkbox
                                    checked={checkedItems.includes(data.id)}
                                    onChange={() =>
                                      handleCheckboxChange(data.id)
                                    }
                                  />
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.id}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.fullName}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.jobInfo?.name}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.about}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.address}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.city}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.degree}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.experience}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.boardInfo?.board_name}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.mediumInfo?.name}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.standards?.map((v) => (`${v?.name},`))}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.subjects?.map((v) => (`${v?.subject},`))}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.mobileNo}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  <a href={data?.resume}>{data?.resume}</a>
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.user?.userInfo?.first_name} - {data?.user?.userInfo?.mobile}
                                </td>
                                <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                                  {data?.selectField}
                                </td>
                                {access?.edit && (
                                  <td
                                    className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                    style={{
                                      position: "sticky",
                                      right: "68px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <ActionButton
                                      key={data.key}
                                      action="edit"
                                      onClick={() => {
                                        setIsModel(true);
                                        setModelType("edit");
                                        setSelectedId(data.id);
                                      }}
                                    />
                                  </td>
                                )}
                                {access?.delete && (
                                  <td
                                    className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                    style={{
                                      position: "sticky",
                                      right: "0",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <ActionButton
                                      key={data.key}
                                      action="delete"
                                      onClick={() => {
                                        setDeleteType("single");
                                        setOpenModel(true);
                                        setSelectAutoPaperGenerationId(
                                          data?.id
                                        );
                                      }}
                                    />
                                  </td>
                                )}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                    data={PaperGenerationData}
                    dataCount={dataCount}
                    numbers={numbers}
                    npage={npage}
                    lastIndex={lastIndex}
                    firstIndex={firstIndex}
                  />
                </section>
              </div>

              {/* Delete */}
              <DeletePopup
                title={"Delete"}
                show={openModel}
                description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                onCancel={() => {
                  setOpenModel(false);
                  setSelectAutoPaperGenerationId("");
                }}
                onClose={() => {
                  setOpenModel(false);
                  setSelectAutoPaperGenerationId("");
                }}
                onDelete={() => {
                  _deletePaperGeneration();
                }}
                cancelButtonRef={cancelButtonRef}
                okText="Delete"
                cancelText="Cancel"
              />
              {/* Delete */}
              <FloatNotification
                show={showFloatNotification}
                title={"Successfully deleted!"}
                type="success"
                description={"Data Deleted."}
                onClick={() => {
                  setShowFloatNotification(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobRequest;
