export const dynamicSequence = (payload) => {
    let orIndex = 0;
    let question_id_sequence =
      //payload.sections_info?.[0]?.division[0]?.question_data?.[0]?.question_id -
      0;
    let andId = 0;
    let data = payload?.map((section, i) => {
      return {
        ...section,
        division: section?.division?.map((div, j) => {
          return {
            ...div,
            question_data: div.question_data?.map((question, k) => {
              question_id_sequence++;
              if (
                question.isOr ||
                (question.isAnd && div.question_data[k - 1]?.isAnd)
              ) {
                orIndex += 1;
              }
              if(question.isAnd){
                andId += 1
              } else {
                andId = 0
              }
              const question_id =  Number(question_id_sequence) - orIndex
              return {
                ...question,
                question_id: question_id,
                andId: andId
              };
            }),
          };
        }),
      };
    });
  
    return data;
  };

  export const clearChapters = (payload) => {
    return payload.map(section => ({
      ...section,
      division: section.division.map(div => ({
        ...div,
        question_data: div.question_data.map(question => ({
          ...question,
          chapter: "",
          qtype: ""  // or you can use `undefined` or just omit the `chapter` field entirely
        }))
      }))
    }));
  };

  export const clearQType = (payload) => {
    return payload.map(section => ({
      ...section,
      division: section.division.map(div => ({
        ...div,
        question_data: div.question_data.map(question => ({
          ...question,
          qtype: "" // or you can use `undefined` or just omit the `chapter` field entirely
        }))
      }))
    }));
  };