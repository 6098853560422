import { AuthLayout } from "./AuthLayout";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Input } from "./Input";
import { Button } from "./Button";
import { useFormik } from "formik";
import { Api } from "../Api/Api";
import { ErrorToast } from "./toast";
import { useState } from "react";
import { saveEncryptedDataToLocalStorage } from "../Api/encryption";
import { getDecryptedDataFromLocalStorage } from "../Api/encryption";

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let userData = getDecryptedDataFromLocalStorage("userData");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      username: yup
        .string()
        .min(2, "Too Short!")
        .max(20, "Must be 20 characters or less")
        .required("Required")
        .matches(/^\S*$/, "Username cannot contain spaces"),

      password: yup
        .string()
        .required("Please enter your password")
        .matches(/^\S*$/, "Username cannot contain spaces"),

      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // ),
    }),
    onSubmit: async (values) => {
      const body = values;

      try {
        setIsLoading(true);
        const { data } = await Api.postRequest("api/auth/admin/login", body);
        const req = JSON.parse(data);

        if (req?.code === 200) {
          // localStorage.setItem("token", JSON.stringify(req?.token));
          // localStorage.setItem("userData", JSON.stringify(req?.data));
          saveEncryptedDataToLocalStorage("token", req?.token);
          saveEncryptedDataToLocalStorage("userData", req?.data);
          const dashboardLink = document.createElement("a");

          if (req?.data?.access) {
            let accessArray = JSON.parse(`[${req?.data?.access}]`);
            const customOrder = [
              "Dashboard",
              "Master",
              "Promocode",
              "Topic",
              "Question",
              "Approve Question",
              "Advertisement",
              "Notification",
              "Shop",
              "Paper",
              "Report",
              "Access",
              "Job",
            ];
            accessArray.sort((a, b) => {
              const indexA = customOrder.indexOf(a.name);
              const indexB = customOrder.indexOf(b.name);
              if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
              } else if (indexA !== -1) {
                return -1;
              } else if (indexB !== -1) {
                return 1;
              } else {
                return 0;
              }
            });

            const firstViewAccess = accessArray.filter((item) => item.view);

            const nameAccess = firstViewAccess[0].name;
            if (nameAccess) {
              if (nameAccess === "Dashboard") {
                dashboardLink.href = "/dashboard";
              } else if (nameAccess === "Master") {
                dashboardLink.href = "/master/board";
              } else if (nameAccess === "Promocode") {
                dashboardLink.href = "/promo-code";
              } else if (nameAccess === "Topic") {
                dashboardLink.href = "/topic";
              } else if (nameAccess === "Question") {
                dashboardLink.href = "/question";
              } else if (nameAccess === "Approve Question") {
                dashboardLink.href = "/all-question/pending-question";
              } else if (nameAccess === "Advertisement") {
                dashboardLink.href = "/advertisement/learning-images";
              } else if (nameAccess === "Notification") {
                dashboardLink.href = "/notification";
              } else if (nameAccess === "Shop") {
                dashboardLink.href = "/shop/books";
              } else if (nameAccess === "Paper") {
                dashboardLink.href = "/paper-generation/auto";
              } else if (nameAccess === "Report") {
                dashboardLink.href = "/report";
              }else if (nameAccess === "Access") {
                dashboardLink.href = "/access/admin";
              }
            }
          }
          dashboardLink.click();
          setIsLoading(false);

          // history.push('/dashboard');
        } else {
          ErrorToast(req?.message);
          setIsLoading(false);
        }
      } catch (error) {
        ErrorToast(error?.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    },
  });

  const onKeyDownHandler = e => {
    if (e.keyCode === 13 && !isLoading) {
      handleSubmit();
    }
  };

  return (
    <>
      <AuthLayout>
        <div className="flex flex-col items-center justify-center">
          <h2 className="mt-16 text-[#344767] text-3xl font-semibold text-center">
            Log In
          </h2>
          <p className="text-[#7B809A] text-lg font-regular mt-4 font-roboto">
            Enter your email and password to Log In
          </p>
        </div>
        <div className="">
          <div className="mt-6">
            <form 
            onKeyDown={onKeyDownHandler}
            action="#" method="POST" className="space-y-4">
              <Input
                label="User Name"
                id="username"
                name="username"
                placeholder="User Name"
                type="text"
                value={values.username}
                onChange={handleChange}
                error={errors.username && touched.username ? true : false}
                errorText={errors.username}
              />
              <Input
                label="Password"
                id="currentPassword"
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange}
                error={errors.password && touched.password ? true : false}
                errorText={errors.password}
                setShowPassword={setShowPassword}
                showPassword={showPassword}
                togglePasswordVisibility={togglePasswordVisibility}
                isPassword={true}
              />

              {/* <div className="flex justify-end items-center">
                <div className="text-sm text-right">
                  <button
                    type="button"
                    onClick={() => navigate("/forgotpassword")}
                    className="font-medium text-blue-600 "
                  >
                    Forgot your password?
                  </button>
                </div>
              </div> */}
              <div className="pt-1">
                <Button
                  isLight
                  id=""
                  type="button"
                  label={isLoading ? "Loding..." : "LOGIN"}
                  onClick={() => {
                    if (!isLoading) {
                      handleSubmit();
                    }
                    // setIsLoading(true);
                  }}
                  abc="bg-gradient-to-b from-[#1190E4] to-[#3552D8] font-poppins"
                />
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
