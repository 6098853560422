import Pagination from "./Pagination";
import { useEffect, useRef, useState } from "react";
import {
  ChevronUpDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Button } from "./Button";
import Model from "./Model";
import { Input } from "./Input";
import MasterTitle from "./MasterTitle";
import { Checkbox } from "./Checkbox";
import { SortData } from "./SortData";
import Records from "./Records";
import FloatNotification from "./FloatNotification";
import Select from "./Select";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import DeletePopup from "./DeletePopup";
import ActionPopup from "./ActionPopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { config } from "../Api/config";
import { Avatar } from "@mui/material";

const Subject = (props) => {
  const access = props?.access?.filter((e) => e?.name === "Master")?.[0];
  const [modelType, setModelType] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showFloatNotification, setShowFloatNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [SubjectData, setSubjectData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [standard, setStandard] = useState("");
  const [semester, setSemester] = useState("");
  const [subject, setSubject] = useState("");
  const [label, setLabel] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [status, setStatus] = useState("");
  const [standardDataCount, setStandardDataCount] = useState("");
  const [semesterDataCount, setSemesterDataCount] = useState("");
  const [selectionDataStandard, setSelectionDataStandard] = useState([]);
  const [selectionDataSemester, setSelectionDataSemester] = useState([]);
  const [search, setSearch] = useState("");
  const [standardDataFilter, setStandardDataFilter] = useState([]);
  const [semesterDataFilter, setSemesterDataFilter] = useState([]);
  const [mediumDataFilter, setMediumDataFilter] = useState([]);
  const [boardDataFilter, setBoardDataFilter] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [subjectQuestion, setSubjectQuestion] = useState({
    board: "",
    medium: "",
    standard: "",
    semester: "",
    subject: "",
    position: "",
    offerPrice: "",
    purchasePrice: "",
    status: "0",
    isregistration: "",
    learningSection: "",
  });

  const [error, setError] = useState({});
  const lastIndex = (currentPage - 1) * recordsPerPage;
  const firstIndex = lastIndex;
  const npage = Math.ceil(dataCount / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [isLoader, setIsLoader] = useState(false);
  const [SelectSubjectId, setSelectSubjectId] = useState();
  const [isModelLoader, setisModelLoader] = useState(false);
  const [mediumData, setMediumData] = useState([]);
  const [boardData, setBoardData] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteType, setDeleteType] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [titleData, setTitleData] = useState([
    {
      title: "IMAGE",
      sort: 1,
      fieldName: "image",
    },
    {
      title: "BOARD",
      sort: 1,
      fieldName: "board",
    },
    {
      title: "MEDIUM",
      sort: 1,
      fieldName: "medium",
    },
    {
      title: "STANDARD",
      sort: 1,
      fieldName: "standard",
    },
    {
      title: "SEMESTER",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "SUBJECT",
      sort: 1,
      fieldName: "semester",
    },
    {
      title: "POSITION",
      sort: 1,
      fieldName: "position",
    },
    {
      title: "BATCH REGISTRATION",
      sort: 1,
      fieldName: "batch_registration",
    },
    {
      title: "LEARNING SECTION",
      sort: 1,
      fieldName: "learning_section",
    },
    {
      title: "OFFER PRICE",
      sort: 1,
      fieldName: "offer price",
    },

    {
      title: "PURCHASE PRICE",
      sort: 1,
      fieldName: "offer price",
    },
    {
      title: "STATUS",
      sort: 1,
      fieldName: "offer price",
    },
    {
      title: access?.edit ? "EDIT" : "",
      sort: 1,
      fieldName: "",
    },
    {
      title: access?.delete ? "DELETE" : "",
      sort: 1,
      fieldName: "",
    },
  ]);

  useEffect(() => {
    getSubjectData(search, recordsPerPage, currentPage);
  }, [ recordsPerPage, currentPage]);

  const applyFilter = () => {
    getSubjectData(search, recordsPerPage, currentPage);
  };

  const getSubjectData = async (search, limit, offset) => {
    try {
      setIsLoader(true);
      const { data } = await Api.getRequest(
        `api/subject?offset=${offset}&limit=${limit}&search=${search || ""}`
      );
      const req = JSON.parse(data);
      setDataCount(req?.totalCount);
      setSubjectData(req?.data);
      setIsLoader(false);
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (!subjectQuestion?.medium) {
      formIsValid = false;
      errors["medium"] = "Please select medium.";
    }
    if (!subjectQuestion?.board) {
      formIsValid = false;
      errors["board"] = "Please select board.";
    }
    if (!subjectQuestion?.standard) {
      formIsValid = false;
      errors["standard"] = "Please select standard.";
    }
    if (!subjectQuestion?.semester) {
      formIsValid = false;
      errors["semester"] = "Please select semester.";
    }
    if (!subjectQuestion?.subject) {
      formIsValid = false;
      errors["subject"] = "Please enter subject.";
    }
    // if (!subjectQuestion?.label) {
    //   formIsValid = false;
    //   errors["label"] = "Please enter label.";
    // }
    if (!subjectQuestion?.position) {
      formIsValid = false;
      errors["position"] = "Please enter position.";
    }
    if (!subjectQuestion?.offerPrice) {
      formIsValid = false;
      errors["offerPrice"] = "Please enter offer price.";
    }
    if (!subjectQuestion?.purchasePrice) {
      formIsValid = false;
      errors["purchasePrice"] = "Please enter purchase price.";
    }
    if (subjectQuestion?.status != 0 && subjectQuestion?.status != 1) {
      formIsValid = false;
      errors["status"] = "Please enter status.";
    }
    if (subjectQuestion?.isregistration != 0 && subjectQuestion?.isregistration != 1) {
      console.log(subjectQuestion?.isregistration, "subjectQuestion?.isregistration");
      formIsValid = false;
      errors["isregistration"] = "Please enter Batch Registration.";
    }
    if (subjectQuestion?.learningSection != 0 && subjectQuestion?.learningSection != 1) {
      formIsValid = false;
      errors["learningSection"] = "Please enter Learning Section.";
    }
    setError(errors);
    return formIsValid;
  };

  const _getById = async (id) => {
    try {
      setisModelLoader(true);
      if (id) {
        const { data } = await Api.getRequest(
          `api/subject/getSubjectByID/${id || ""}`
        );
        const req = JSON.parse(data);
        if (req?.status === 200) {
          setisModelLoader(false);

          setSubjectQuestion({
            board: req?.data?.StandardInfo?.boardInfo || "",
            medium: req?.data?.StandardInfo?.mediumInfo || "",
            standard: req?.data?.StandardInfo || "",
            semester: req?.data?.SemesterInfo || "",
            subject: req?.data?.subject || "",
            // label: req?.data?.label || "",
            position: req?.data?.position,
            offerPrice: req?.data?.offer_price || "",
            purchasePrice: req?.data?.purchase_price || "",
            status: req?.data?.status || "0",
            isregistration: req?.data?.isregistration || 0,
            learningSection: req?.data?.learningSection || 0,
          });
          setSelectedFile(req?.data?.image);
        } else {
          ErrorToast(req.message);
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    if (selectedId) {
      _getById(selectedId);
    }
  }, [selectedId]);

  const handleAddModelSubmit = async () => {
    console.log("subjectQuestion.status----", typeof subjectQuestion.status);
    try {
      if (validation()) {
        setIsLoading(true);
        // const formData = new FormData();
        // formData.append("image", selectedFile);

        // const response = await fetch(
        //   config.baseURL + "api/upload/single_file",
        //   {
        //     method: "POST",
        //     // headers: {
        //     //   Authorization: "Bearer " + token,
        //     // },
        //     body: formData,
        //   }
        // );
        // const result = await response.json();
        if (modelType === "edit") {
          try {
            let body = {
              board: subjectQuestion?.board?.id,
              medium: subjectQuestion?.medium?.id,
              standard: subjectQuestion?.standard?.id,
              semester: subjectQuestion?.semester?.id,
              subject: subjectQuestion?.subject,
              // label: subjectQuestion?.label,
              position: subjectQuestion?.position,
              offer_price: subjectQuestion?.offerPrice,
              purchase_price: subjectQuestion?.purchasePrice,
              status: subjectQuestion?.status,
              isregistration: subjectQuestion?.isregistration,
              learningSection: subjectQuestion?.learningSection,
              image: selectedFile || '',
            };
            const data = await Api.putRequest(
              `api/subject/update/${selectedId}`,
              body
            );
            if (data?.data?.status === 200) {
              SuccessToast(data?.data?.message);
              getSubjectData(search, recordsPerPage, currentPage);
              setSubjectQuestion({
                medium: "",
                board: "",
                standard: "",
                semester: "",
                subject: "",
                // label: "",
                position: "",
                offerPrice: "",
                purchasePrice: "",
                status: 0,
                isregistration: 0,
                learningSection: 0
              });
              setSelectedFile(null);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setIsModel(false);
              setSelectedId("");
              setMediumData([]);
              setSelectionDataSemester([]);
              setSelectionDataStandard([]);
            } else {
              ErrorToast(data?.data?.message);
            }
          } catch (error) {
            ErrorToast(error);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        } else {
          try {
            let body = {
              board: subjectQuestion?.board?.id,
              medium: subjectQuestion?.medium?.id,
              standard: subjectQuestion?.standard?.id,
              semester: subjectQuestion?.semester?.id,
              subject: subjectQuestion?.subject,
              // label: subjectQuestion?.label,
              image: selectedFile || '',
              position: subjectQuestion?.position,
              offer_price: subjectQuestion?.offerPrice,
              purchase_price: subjectQuestion?.purchasePrice,
              isregistration: subjectQuestion?.isregistration,
              learningSection: subjectQuestion?.learningSection,
              status: subjectQuestion?.status,
            };
            const { data } = await Api.postRequest("api/subject/create", body);
            const req = JSON.parse(data);
            if (req?.status === 200) {
              SuccessToast(req?.message);
              getSubjectData(search, recordsPerPage, currentPage);
              setSubjectQuestion((prevState) => ({
                ...prevState,
                subject: "",
                position: "",
                offerPrice: "",
                purchasePrice: "",
              }));
              setSelectedFile(null);
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);
              setSelectedId("");
            } else {
              ErrorToast(req?.message);
            }
          } catch (error) {
            ErrorToast(error);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      ErrorToast(error?.message);
      setIsLoader(false);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);

    const response = await fetch(
      config.baseURL + "api/upload/single_file",
      {
        method: "POST",
        // headers: {
        //   Authorization: "Bearer " + token,
        // },
        body: formData,
      }
    );
    const result = await response.json();
    
    // setImage(result?.data);
    setSelectedFile(result?.data);
  };

  const handleChooseFileClick = () => {
    document.getElementById("fileUpload").click();
  };

  const getBoardData = async () => {
    try {
      const { data } = await Api.getRequest(`api/board`);
      const req = JSON.parse(data);
      setBoardData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getMediumData = async () => {
    try {
      let body = `?board=${subjectQuestion?.board?.id}`;
      const { data } = await Api.getRequest(`api/medium${body}`);
      const req = JSON.parse(data);
      setMediumData(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getStandardData = async () => {
    try {
      let body = `?medium=${subjectQuestion?.medium?.id}`;
      const { data } = await Api.getRequest(`api/standard${body}`);
      const req = JSON.parse(data);
      setSelectionDataStandard(req?.data);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const getSemesterData = async () => {
    try {
      let body = `?standard=${subjectQuestion?.standard?.id}`;
      const { data } = await Api.getRequest(`api/semester${body}`);
      const req = JSON.parse(data);
      setSelectionDataSemester(req?.data?.rows);
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  useEffect(() => {
    getBoardData();
  }, []);

  useEffect(() => {
    if (subjectQuestion?.board) {
      getMediumData();
    }
  }, [subjectQuestion?.board]);

  useEffect(() => {
    if (subjectQuestion?.medium) {
      getStandardData();
    }
  }, [subjectQuestion?.medium]);

  useEffect(() => {
    if (subjectQuestion?.standard) {
      getSemesterData();
    }
  }, [subjectQuestion?.standard]);

  const handelSubject = (name, value) => {
    console.log(name, value);
    if (name === "board") {
      setSubjectQuestion((oldValue) => ({
        ...oldValue,
        medium: "",
        standard: "",
        semester: "",
        [name]: value,
      }));
      setMediumData([]);
      setSelectionDataSemester([]);
      setSelectionDataStandard([]);
    } else if (name === "medium") {
      setSubjectQuestion((oldValue) => ({
        ...oldValue,
        standard: "",
        semester: "",
        [name]: value,
      }));
      setSelectionDataSemester([]);
      setSelectionDataStandard([]);
    } else if (name === "standard") {
      setSubjectQuestion((oldValue) => ({
        ...oldValue,
        semester: "",
        [name]: value,
      }));
      setSelectionDataSemester([]);
    } else {
      setSubjectQuestion((oldValue) => ({
        ...oldValue,
        [name]: value,
      }));
    }
  };

  const _deleteSubject = async (id) => {
    try {
      let data;
      if (deleteType === "multi") {
        const body = {
          ids: checkedItems,
        };
        const response = await Api.postRequest(`api/subject/delete`, body);
        data = response.data;
      } else {
        const body = {
          ids: [SelectSubjectId],
        };
        const response = await Api.postRequest(`api/subject/delete`, body);
        data = response.data;
      }

      // const { data } = await Api.postRequest(
      //   `api/subject/delete/${SelectSubjectId}`
      // );
      const req = JSON.parse(data);
      if (req?.status === 200) {
        SuccessToast(req?.message);
        getSubjectData(search, recordsPerPage, currentPage);
        setOpenModel(false);
        setSelectSubjectId("");
      } else {
        ErrorToast(req?.message);
      }
    } catch (error) {
      ErrorToast(error?.message);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isChecked = prevState.includes(id);

      const updatedItems = isChecked
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id];

      return updatedItems;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <MasterTitle
            title="Subject"
            description="Below are all the list of subject."
          />
        </div>

        <div>
          <div style={{ display: "flex", gap: "5px" }}>
            {access?.delete && (
              <Button
                id=""
                type="submit"
                icon={TrashIcon}
                label={"DELETE SUBJECT"}
                onClick={() => {
                  setDeleteType("multi");
                  if (checkedItems.length > 0) {
                    setOpenModel(true);
                  }
                }}
              />
            )}
            {access?.add && (
              <Button
                id=""
                type="submit"
                icon={PlusIcon}
                label={"ADD SUBJECT"}
                onClick={() => {
                  setSubjectQuestion({});
                  setSelectedFile(null);
                  setIsModel(true);
                  setModelType("add");
                }}
              />
            )}
          </div>

          <Model
            selectedId={selectedId}
            title={modelType === "add" ? "Add Subject" : "Edit Subject"}
            show={isModel}
            onCancel={() => {
              // setSubjectQuestion({});
              setSelectedFile(null);
              setIsModel(false);
            }}
            onClose={() => {
              setIsModel(false);
              setMediumData([]);
              setSelectionDataSemester([]);
              setSelectionDataStandard([]);
            }}
            onOk={() => handleAddModelSubmit()}
            okText="SUBMIT"
            cancelText="CLOSE"
            width="max-w-3xl"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isModelLoader ? (
              <tr
                style={{
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <td>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              <>
                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                  <div className="w-full">
                    <form className="mb-2">
                      <label
                        htmlFor="fileUpload"
                        className="mb-3 block text-sm font-medium text-[#252F40] font-poppins"
                      >
                        Upload photo
                      </label>
                      <input
                        type="file"
                        id="fileUpload"
                        class="hidden"
                        onChange={handleImageChange}
                      ></input>
                      <div className="flex justify-between">
                        <div className="flex items-center ">
                          <button
                            id=""
                            type="button"
                            label={"Choose file"}
                            onClick={handleChooseFileClick}
                            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                          >
                            Choose file
                          </button>
                          {/* <div className="ml-1 block text-base font-medium text-[#252F40] font-poppins ">
                            {selectedFile?.name}
                          </div> */}
                        </div>
                        {modelType === "add" ? (
                          <div className="flex-shrink-0 w-50 h-10 ">
                          <img className="w-full h-full" src={selectedFile} alt="" />
                        </div>
                        ) : (
                          <div className="flex-shrink-0 w-50 h-10 ">
                            <img className="w-full h-full" src={selectedFile} alt="" />
                          </div>
                        )}
                      </div>
                    </form>
                    <Typography
                      className="my-2"
                      variant="caption"
                      style={{ color: "#d32f2f" }}
                    >
                      {!selectedFile ? error?.image : ""}
                    </Typography>
                  </div>
                </div>

                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                  <div className="w-full">
                    <Select
                      // value={standardQuestion?.type?.name}
                      data={boardData || []}
                      className="font-regular mb-3"
                      label="Board"
                      required
                      placeholder="All Board"
                      onChange={(e) => handelSubject("board", e)}
                      value={subjectQuestion?.board || ""}
                      type={"board"}
                      getBoard={getBoardData}
                      isPage={true}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.board ? error?.board : ""}
                    </Typography>
                  </div>
                  <div className="w-full">
                    <Select
                      // value={standardQuestion?.type?.name}
                      data={mediumData || []}
                      className="font-regular mb-3"
                      label="Medium"
                      required
                      placeholder="All Medium"
                      onChange={(e) => handelSubject("medium", e)}
                      value={subjectQuestion?.medium || ""}
                      type={"medium"}
                      getMedium={getMediumData}
                      isPage={true}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.medium ? error?.medium : ""}
                    </Typography>
                  </div>
                </div>

                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 ">
                  <div className="w-full">
                    <Select
                      data={selectionDataStandard || []}
                      className="font-regular mb-3"
                      label="Standard"
                      required
                      placeholder="All Standard"
                      value={subjectQuestion.standard || ""}
                      onChange={(e) => handelSubject("standard", e)}
                      type={"standard"}
                      getStandard={getStandardData}
                      isPage={true}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.standard ? error?.standard : ""}
                    </Typography>
                  </div>
                  <div className="w-full flex flex-col">
                    <Select
                      data={selectionDataSemester || []}
                      className="font-regular mb-3"
                      label="Semester"
                      required
                      placeholder="All semester"
                      value={subjectQuestion.semester || ""}
                      onChange={(e) => handelSubject("semester", e)}
                      type={"semester"}
                      getSemester={getSemesterData}
                      isPage={true}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.semester ? error?.semester : ""}
                    </Typography>
                    {/* <Input
                  label="Semester"
                  id=""
                  name="School Type"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full"
                  value={semester}
                  onChange={(e) => setSemester(e.target.value)}
                /> */}
                  </div>
                </div>

                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                  <div className="w-full flex flex-col">
                    <Input
                      label="Subject"
                      id=""
                      name="School Type"
                      type="text"
                      autoComplete="given-name"
                      required
                      className="w-full"
                      value={subjectQuestion.subject || ""}
                      onChange={(e) => handelSubject("subject", e.target.value)}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.subject ? error?.subject : ""}
                    </Typography>
                  </div>
                  {/* <div className="w-full flex flex-col">
                    <Input
                      label="Label"
                      id=""
                      name="School Type"
                      type="text"
                      autoComplete="given-name"
                      required
                      className="w-full"
                      value={subjectQuestion.label || ""}
                      onChange={(e) => handelSubject("label", e.target.value)}
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.label ? error?.label : ""}
                    </Typography>
                  </div> */}
                  <div className="w-full flex flex-col">
                    {" "}
                    <Input
                      label="Position"
                      id="firstname"
                      name="firstname"
                      type="text"
                      autoComplete="given-name"
                      required
                      className="w-full mb-3"
                      value={subjectQuestion.position || ""}
                      onChange={(e) =>
                        handelSubject("position", e.target.value)
                      }
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.position ? error?.position : ""}
                    </Typography>
                  </div>
                </div>

                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6 mb-3">
                  <div className="w-full flex flex-col">
                    <Input
                      label="Offer Price"
                      id=""
                      name="School Type"
                      type="text"
                      autoComplete="given-name"
                      required
                      className="w-full"
                      value={subjectQuestion.offerPrice || ""}
                      onChange={(e) =>
                        handelSubject("offerPrice", e.target.value)
                      }
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.offerPrice ? error?.offerPrice : ""}
                    </Typography>
                  </div>
                  <div className="w-full flex flex-col">
                    <Input
                      label="Purchase Price"
                      id="firstname"
                      name="firstname"
                      type="text"
                      autoComplete="given-name"
                      required
                      className="w-full"
                      value={subjectQuestion.purchasePrice || ""}
                      onChange={(e) =>
                        handelSubject("purchasePrice", e.target.value)
                      }
                    />
                    <Typography variant="caption" color={"error"}>
                      {!subjectQuestion?.purchasePrice
                        ? error?.purchasePrice
                        : ""}
                    </Typography>
                  </div>
                </div>
                {/* <Input
                  label="Status"
                  id=""
                  name="School Type"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="w-full"
                  value={subjectQuestion.status || ""}
                  onChange={(e) => handelSubject("status", e.target.value)}
                /> */}
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{
                      fontFamily: "poppins",
                      color: "#252F40",
                      opacity: "1",
                    }}
                  >
                    Status
                  </FormLabel>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => handelSubject("status", e.target.value)}
                    value={subjectQuestion.status}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Enabled"
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Disabled"
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant="caption" color={"error"}>
                  {subjectQuestion?.status == "" ||
                    subjectQuestion?.status == null
                    ? error?.status
                    : ""}
                </Typography>
                <div className="w-full flex flex-col">
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{
                        fontFamily: "poppins",
                        color: "#252F40",
                        opacity: "1",
                      }}
                    >
                      Batch Registration
                    </FormLabel>
                    <RadioGroup
                      row
                      required
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => handelSubject("isregistration", e.target.value)}
                      value={subjectQuestion.isregistration}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <Typography variant="caption" color={"error"}>
                  {subjectQuestion?.isregistration != 0 && subjectQuestion?.isregistration != 1
                    ? error?.isregistration
                    : ""}
                </Typography>
                <div className="w-full flex flex-col">
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{
                        fontFamily: "poppins",
                        color: "#252F40",
                        opacity: "1",
                      }}
                    >
                      Learning section
                    </FormLabel>
                    <RadioGroup
                      row
                      required
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => handelSubject("learningSection", e.target.value)}
                      value={subjectQuestion.learningSection}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <Typography variant="caption" color={"error"}>
                  {subjectQuestion?.learningSection != 0 && subjectQuestion?.learningSection != 1
                    ? error?.learningSection
                    : ""}
                </Typography>
              </>
            )}
          </Model>
        </div>
      </div>
      <div className="sm:mb-0 overflow-hidden">
        <div className="flex flex-col mt-4">
          <section
            className="bg-white rounded-2xl flex flex-col shadow-sm "
            style={{
              width: "100%",
              height: "75vh",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            <div className="flex justify-between items-center mt-6 px-8 mb-4">
              <div  style={{ display: "flex", gap:'5px' }}>
              <Input
                id=""
                name="Search"
                type="text"
                placeholder="Search here..."
                autoComplete="given-name"
                required
                className="w-72"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setCurrentPage(1);
                }}
                value={search}
              />
                 <Button
                    onClick={() => applyFilter()}
                    id=""
                    type="button"
                    // label={"SEARCH"}
                    searchicon={true}
                  />
              </div>
              <div>
                <Records
                  recordsPerPage={recordsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsPerPage={setRecordsPerPage}
                  dataCount={dataCount}
                />
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "58vh",
                height: "60vh",
                width: "100%",
              }}
            >
              {" "}
              {!isLoader && SubjectData?.length <= 0 ? (
                <NoDataFound />
              ) : (
                <table style={{ width: "100%" }}>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f4f7f8",
                      zIndex: 1,
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#7B809A",
                        textTransform: "uppercase",
                        backgroundColor: "#f4f7f8",
                      }}
                    >
                      {" "}
                      <th
                        scope="col"
                        style={{ width: "3%", padding: "10px" }}
                      ></th>
                      {titleData.map(
                        (v, ind) =>
                          v.title && (
                            <th
                              key={ind}
                              className="py-2 px-8"
                              style={{
                                padding: "12px",
                                backgroundColor: "#f4f7f8",
                                position:
                                  v.title === "EDIT" || v.title === "DELETE"
                                    ? "sticky"
                                    : "",
                                right:
                                  v.title === "EDIT"
                                    ? "80px"
                                    : v.title === "DELETE"
                                      ? "0"
                                      : "",
                              }}
                            >
                              <div className="flex justify-between">
                                <span>{v.title}</span>
                                {v.title !== "EDIT" && v.title !== "DELETE" && (
                                  <span>
                                    <ChevronUpDownIcon
                                      onClick={() => {
                                        let res = SortData(
                                          SubjectData,
                                          v.fieldName,
                                          v.sort
                                        );

                                        let copyData = [...titleData];
                                        copyData[ind].sort =
                                          v.sort > 0 ? -1 : 1;
                                        setTitleData(copyData);
                                        setSubjectData(res);
                                      }}
                                      className="w-5 h-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {isLoader ? (
                      <tr style={{ height: "25rem" }}>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      SubjectData.map((data) => (
                        <tr
                          key={data.key}
                          className="my-4 px-8 text-[#000] font-reguler text-sm font-medium"
                        >
                          <td className="px-6 py-3 whitespace-no-wrap">
                            <Checkbox
                              checked={checkedItems.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            <div className="flex-shrink-0 w-35 h-10 ">
                              {/* <img
                                src={data.image}
                                alt=""
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                              /> */}
                              
                              <Avatar
                                alt="Image"
                                src={data?.image}
                                
                                sx={{
                                  borderRadius: "0%",
                                  width: "150px",
                                  height: "40px",
                                  resize: "contain",
                                }}
                              />
                            </div>
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data.boardInfo?.board_name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data.mediumInfo?.name || "-"}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                            {data.StandardInfo.name}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data?.SemesterInfo?.semester}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.subject}
                          </td>
                          {/* <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.label}
                          </td> */}{" "}
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.position}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap p-8 py-2.5 ">
                            {data?.isregistration}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data?.learningSection}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.offer_price}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.purchase_price}
                          </td>
                          <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                            {data.status === 1 ? (
                              <div className="inline-flex items-center rounded-full text-sm font-semibold leading-5 text-[#000]  space-x-2">
                                <CheckCircleIcon className="w-6 text-green-600 " />
                                <span> enabled</span>
                              </div>
                            ) : (
                              <div className="inline-flex items-center rounded-full text-sm font-semibold leading-5 text-[#000]  space-x-2">
                                <XCircleIcon className="w-6 text-red-600" />
                                <span> disabled</span>
                              </div>
                            )}
                          </td>
                          {access?.edit && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "68px",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="edit"
                                onClick={() => {
                                  setIsModel(true);
                                  setModelType("edit");
                                  setSelectedId(data.id);
                                }}
                              />
                            </td>
                          )}
                          {access?.delete && (
                            <td
                              className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                              style={{
                                position: "sticky",
                                right: "0",
                                backgroundColor: "white",
                              }}
                            >
                              <ActionButton
                                key={data.key}
                                action="delete"
                                onClick={() => {
                                  setDeleteType("single");
                                  setOpenModel(true);
                                  setSelectSubjectId(data?.id);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              data={SubjectData}
              dataCount={dataCount}
              numbers={numbers}
              npage={npage}
              lastIndex={lastIndex}
              firstIndex={firstIndex}
            />
          </section>
        </div>
        {/* Delete */}
        <DeletePopup
          title={"Delete"}
          show={openModel}
          description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
          onCancel={() => {
            setOpenModel(false);
            setSelectSubjectId("");
          }}
          onClose={() => {
            setOpenModel(false);
            setSelectSubjectId("");
          }}
          onDelete={() => {
            _deleteSubject();
          }}
          cancelButtonRef={cancelButtonRef}
          okText="Delete"
          cancelText="Cancel"
        />
        {/* Delete */}
        <FloatNotification
          show={showFloatNotification}
          title={"Successfully deleted!"}
          type="success"
          description={"Data Deleted."}
          onClick={() => {
            setShowFloatNotification(false);
          }}
        />
      </div>
    </div>
  );
};

export default Subject;
