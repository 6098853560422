import React, { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import Model from "./Model";
import MasterTitle from "./MasterTitle";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import Records from "./Records";
import { SortData } from "./SortData";
import FloatNotification from "./FloatNotification";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import Select from "./Select";
import DeletePopup from "./DeletePopup";
import { Api } from "../Api/Api";
import { ErrorToast, SuccessToast } from "./toast";
import { useDebounce } from "../hooks/use-debounce";
import { IconButton, Typography } from "@mui/material";
import NoDataFound from "./NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "./ActionButton";
import { Close as CloseIcon } from '@mui/icons-material';
import MultiSelect from "./MultiSelect";
import { clearQType, dynamicSequence } from "./constants/helper";

const AutoPaperFlow = (props) => {
    const access = props?.access?.filter((e) => e?.name === "Paper")?.[0];
    const [isModel, setIsModel] = useState(false);
    const [modelType, setModelType] = useState("");
    const [openModel, setOpenModel] = useState(false);
    const [showFloatNotification, setShowFloatNotification] = useState(false);

    const [selectedId, setSelectedId] = useState(null);

    const [search, setSearch] = useState("");
    const debouncedValue = useDebounce(search, 800);
    const [isLoader, setIsLoader] = useState(false);
    const [isModelLoader, setisModelLoader] = useState(false);
    const [papergenfilter, setpapergenfilter] = useState({
        board: "",
        medium: "",
        standard: "",
        semester: "",
        subject: "",
    });
    const [modelPaperGenerationData, SetModelPaperGenerationData] = useState({
        board: "",
        medium: "",
        standard: "",
        semester: "",
        subject: "",
        totalMarks: "",
        chapter: [],
    });
    const initialData = [
        {
            sectionName: "Section A",
            section: "",
            division: [
                {
                    marks: 0,
                    noticetitle: "",
                    question_data: [{
                        question_id: 1,
                        qtype: '',
                        isOr: false,
                        isAnd: false,
                        andId: 0,
                    }],
                }
            ]
        }
    ];

    const [sections, setSections] = useState(initialData);
    const [boardData, setBoardData] = useState([]);
    const [mediumData, setMediumData] = useState([]);
    const [standardData, setStandardData] = useState([]);
    const [semesterData, setSemesterData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);

    const [selectionDataBoard, setSelectionDataBoard] = useState([]);
    const [selectionDataMedium, setSelectionDataMedium] = useState([]);
    const [selectionDataStandard, setSelectionDataStandard] = useState([]);
    const [selectionDataSubject, setSelectionDataSubject] = useState([]);
    const [selectionDataSemester, setSelectionDataSemester] = useState([]);
    const [selectionDataChapter, setSelectionDataChapter] = useState([]);
    const [viewSection, setViewSection] = useState(true);
    const [qTypeModalData, setQTypeModalData] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [deleteType, setDeleteType] = useState("single");
    const [SelectAutoPaperGenerationId, setSelectAutoPaperGenerationId] =
        useState();
    const cancelButtonRef = useRef(null);
    const [titleData, setTitleData] = useState([
        {
            title: "STANDARD",
            sort: 1,
            fieldName: "standard",
        },
        {
            title: "SEMESTER",
            sort: 1,
            fieldName: "semester",
        },
        {
            title: "SUBJECT",
            sort: 1,
            fieldName: "subject",
        },
        {
            title: "MARKS",
            sort: 1,
            fieldName: "marks",
        },
        {
            title: access?.edit ? "EDIT" : "",
            sort: 1,
            fieldName: "",
        },
        {
            title: access?.delete ? "DELETE" : "",
            sort: 1,
            fieldName: "",
        },
    ]);
    const TotalMarksData = [
        { id: 1, name: "25" },
        { id: 2, name: "30" },
        { id: 3, name: "35" },
        { id: 4, name: "40" },
        { id: 5, name: "50" },
        { id: 6, name: "80" },
        { id: 7, name: "100" },
    ];
    const [error, setError] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50);
    const [dataCount, setDataCount] = useState(0);
    const [PaperGenerationData, setPaperGenerationData] = useState([]);
    const lastIndex = (currentPage - 1) * recordsPerPage;
    const firstIndex = lastIndex;
    const npage = Math.ceil(dataCount / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isResetFilter, setIsResetFilter] = useState(false);
    const getBoardData = async () => {
        try {
            const { data } = await Api.getRequest(`api/board`);
            const req = JSON.parse(data);
            setBoardData(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getMediumData = async () => {
        try {
            let body = `?board=${papergenfilter?.board?.id}`;
            const { data } = await Api.getRequest(`api/medium${body}`);
            const req = JSON.parse(data);
            setMediumData(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getStandardData = async () => {
        try {
            let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}`;
            const { data } = await Api.getRequest(`api/standard${body}`);
            const req = JSON.parse(data);
            setStandardData(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getSemesterData = async () => {
        try {
            let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}`;
            const { data } = await Api.getRequest(`api/semester${body}`);
            const req = JSON.parse(data);
            setSemesterData(req?.data?.rows);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getSubjectData = async () => {
        try {
            let body = `?board=${papergenfilter?.board?.id}&medium=${papergenfilter?.medium?.id}&standard=${papergenfilter?.standard?.id}&semester=${papergenfilter?.semester?.id}`;
            const { data } = await Api.getRequest(`api/subject${body}`);
            const req = JSON.parse(data);
            setSubjectData(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    useEffect(() => {
        if (papergenfilter["board"] !== "") {
            getMediumData();
        }
    }, [papergenfilter["board"]]);

    useEffect(() => {
        if (papergenfilter["medium"] !== "") {
            getStandardData();
        }
    }, [papergenfilter["medium"]]);

    useEffect(() => {
        if (papergenfilter["standard"] !== "") {
            getSemesterData();
        }
    }, [papergenfilter["standard"]]);

    useEffect(() => {
        if (papergenfilter["semester"] !== "") {
            getSubjectData();
        }
    }, [papergenfilter["semester"]]);

    const handleFilter = (name, value) => {
        setpapergenfilter({
            ...papergenfilter,
            [name]: value,
        });
        if (name === "board") {
            setpapergenfilter((oldValue) => ({
                ...oldValue,
                medium: "",
                standard: "",
                semester: "",
                subject: "",
                [name]: value,
            }));
            setMediumData([]);
            setStandardData([]);
            setSemesterData([]);
            setSubjectData([]);
        }
        if (name === "medium") {
            setpapergenfilter((oldValue) => ({
                ...oldValue,
                standard: "",
                semester: "",
                subject: "",
                [name]: value,
            }));
            setStandardData([]);
            setSemesterData([]);
            setSubjectData([]);
        }
        if (name === "standard") {
            setpapergenfilter((oldValue) => ({
                ...oldValue,
                semester: "",
                subject: "",
                [name]: value,
            }));
            setSemesterData([]);
            setSubjectData([]);
        }
        if (name === "semester") {
            setpapergenfilter((oldValue) => ({
                ...oldValue,
                subject: "",
                [name]: value,
            }));
            setSubjectData([]);
        }
    };

    const getBoardModelData = async () => {
        try {
            const { data } = await Api.getRequest(`api/board`);
            const req = JSON.parse(data);
            setSelectionDataBoard(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getMediumModelData = async () => {
        try {
            let body = `?board=${modelPaperGenerationData?.board?.id}`;
            const { data } = await Api.getRequest(`api/medium${body}`);
            const req = JSON.parse(data);
            setSelectionDataMedium(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getStandardModelData = async () => {
        try {
            let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}`;
            const { data } = await Api.getRequest(`api/standard${body}`);
            const req = JSON.parse(data);
            setSelectionDataStandard(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getSemesterModelData = async () => {
        try {
            let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}&standard=${modelPaperGenerationData?.standard?.id}`;
            const { data } = await Api.getRequest(`api/semester${body}`);
            const req = JSON.parse(data);
            setSelectionDataSemester(req?.data?.rows);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getSubjectModelData = async () => {
        try {
            let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}&standard=${modelPaperGenerationData?.standard?.id}&semester=${modelPaperGenerationData?.semester?.id}`;
            const { data } = await Api.getRequest(`api/subject${body}`);
            const req = JSON.parse(data);
            setSelectionDataSubject(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getChapterModelData = async () => {
        try {
            let body = `?board=${modelPaperGenerationData?.board?.id}&medium=${modelPaperGenerationData?.medium?.id}&standard=${modelPaperGenerationData?.standard?.id}&semester=${modelPaperGenerationData?.semester?.id}&subject=${modelPaperGenerationData?.subject?.id}`;
            const { data } = await Api.getRequest(`api/chapter${body}`);
            const req = JSON.parse(data);
            setSelectionDataChapter(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const getQTypeModalData = async () => {
        try {
            let body = `?standard=${modelPaperGenerationData?.standard?.id}&semester=${modelPaperGenerationData?.semester?.id}&subject=${modelPaperGenerationData?.subject?.id}`;
            const { data } = await Api.getRequest(`api/qtype/chapter${body}`);
            const req = JSON.parse(data);
            setQTypeModalData(req?.data);
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    useEffect(() => {
        getBoardData();
        getBoardModelData();
    }, []);

    useEffect(() => {
        if (modelPaperGenerationData["board"] != "") {
            getMediumModelData();
        }
        if (modelPaperGenerationData["medium"] != "") {
            getStandardModelData();
        }
        if (modelPaperGenerationData["standard"] != "") {
            getSemesterModelData();
        }
        if (modelPaperGenerationData["semester"] != "") {
            getSubjectModelData();
        }
        if (modelPaperGenerationData["subject"] != "") {
            getQTypeModalData();
        }
    }, [modelPaperGenerationData]);

    const handelModelData = (name, value) => {
        SetModelPaperGenerationData((oldValue) => ({
            ...oldValue,
            [name]: value,
        }));
        if (name === "board") {
            SetModelPaperGenerationData((oldValue) => ({
                ...oldValue,
                medium: "",
                standard: "",
                semester: "",
                subject: "",
                chapter: "",
                [name]: value,
            }));
            setSections(clearQType(sections))
            setSelectionDataMedium([]);
            setSelectionDataStandard([]);
            setSelectionDataSemester([]);
            setSelectionDataSubject([]);
            setSelectionDataChapter([]);
            setQTypeModalData([]);
        }
        if (name === "medium") {
            SetModelPaperGenerationData((oldValue) => ({
                ...oldValue,
                standard: "",
                semester: "",
                subject: "",
                chapter: "",
                [name]: value,
            }));
            setSections(clearQType(sections))
            setSelectionDataStandard([]);
            setSelectionDataSemester([]);
            setSelectionDataSubject([]);
            setSelectionDataChapter([]);
            setQTypeModalData([]);
        }
        if (name === "standard") {
            SetModelPaperGenerationData((oldValue) => ({
                ...oldValue,
                semester: "",
                subject: "",
                chapter: "",
                [name]: value,
            }));
            setSections(clearQType(sections))
            setSelectionDataSemester([]);
            setSelectionDataSubject([]);
            setSelectionDataChapter([]);
            setQTypeModalData([]);
        }
        if (name === "semester") {
            SetModelPaperGenerationData((oldValue) => ({
                ...oldValue,
                subject: "",
                chapter: "",
                [name]: value,
            }));
            setSections(clearQType(sections))
            setSelectionDataSubject([]);
            setSelectionDataChapter([]);
            setQTypeModalData([]);
        }
        if (name === "subject") {
            SetModelPaperGenerationData((oldValue) => ({
                ...oldValue,
                chapter: "",
                [name]: value,
            }));
            setSections(clearQType(sections))
            setSelectionDataChapter([]);
            setQTypeModalData([]);
        }
    };

    const handleRemoveQuestion = (sectionIndex, divisionIndex, questionIndex) => {
        setSections((oldValue) => {
            let data = oldValue.map((item, index) => {
                if (index === sectionIndex) {
                    return {
                        ...item,
                        division: item.division.map((div, divIndex) => {
                            if (divIndex === divisionIndex) {
                                if (div.question_data.length <= 1) {
                                    return div
                                }
                                return {
                                    ...div,
                                    question_data: div.question_data.filter((data, dataIndex) => dataIndex !== questionIndex),
                                };
                            }
                            return div;
                        }),
                    };
                }
                return item;
            });
            return dynamicSequence(data);
        });
    }
    const handleAddQuestion = (sectionIndex, divisionIndex, isOr, isAnd) => {
        setSections((oldValue) => {
            let data = oldValue.map((item, index) => {
                if (index === sectionIndex) {
                    return {
                        ...item,
                        division: item.division.map((div, divIndex) => {
                            if (divIndex === divisionIndex) {
                                return {
                                    ...div,
                                    question_data: [...div.question_data, {
                                        question_id: div?.question_data[div?.question_data.length - 1]?.question_id + 1,
                                        qtype: '',
                                        isOr: isOr,
                                        isAnd: isAnd,
                                        andId: 0,
                                    }],
                                };
                            }
                            return div;
                        }),
                    };
                }
                return item;
            });
            return dynamicSequence(data);
        });
    }

    const handleSectionShow = (value) => {
        setViewSection(value);
        if (!value) {
            setSections(initialData);
        }
    }

    const _getById = async (id) => {
        try {
            if (id) {
                setisModelLoader(true);
                const { data } = await Api.getRequest(
                    `api/auto-paper-generationnew/getAutoPaper/${id || ""}`
                );
                const req = JSON.parse(data);
                if (req?.status === 200) {
                    setisModelLoader(false);
                    SetModelPaperGenerationData({
                        board: req?.data?.standardInfo?.boardInfo || "",
                        medium: req?.data?.standardInfo?.mediumInfo || "",
                        standard: req?.data?.standardInfo || "",
                        semester: req?.data?.semesterInfo || "",
                        subject: req?.data?.subjectInfo || "",
                        chapter: req?.data?.chapter || "",
                        totalMarks: req?.data?.tmarks || "",
                    });
                    setSections(req?.data?.sectionData);
                } else {
                    ErrorToast(req.message);
                }
            }
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    useEffect(() => {
        if (selectedId) {
            _getById(selectedId);
        }
    }, [selectedId]);

    const validation = () => {
        let errors = {};
        let formIsValid = true;
        if (!modelPaperGenerationData?.board) {
            formIsValid = false;
            errors["board"] = "Please select board.";
        }
        if (!modelPaperGenerationData?.medium) {
            formIsValid = false;
            errors["medium"] = "Please select medium.";
        }
        if (!modelPaperGenerationData?.standard) {
            formIsValid = false;
            errors["standard"] = "Please select standard.";
        }
        if (!modelPaperGenerationData?.semester) {
            formIsValid = false;
            errors["semester"] = "Please select semester.";
        }
        if (!modelPaperGenerationData?.subject) {
            formIsValid = false;
            errors["subject"] = "Please select subject.";
        }
        if (!modelPaperGenerationData?.totalMarks) {
            formIsValid = false;
            errors["totalMarks"] = "Please select Total Marks.";
        }

        sections.forEach((section, sectionIndex) => {
            if (!section.section && viewSection) {
                formIsValid = false;
                if (!errors.sections) errors.sections = {};
                errors.sections[sectionIndex] = errors.sections[sectionIndex] || {};
                errors.sections[sectionIndex].section = "Please enter Section Label.";
            }

            // Validate each division within the section
            section.division.forEach((division, divisionIndex) => {
                if (!division.noticetitle) {
                    formIsValid = false;
                    if (!errors.sections) errors.sections = {};
                    if (!errors.sections[sectionIndex]) errors.sections[sectionIndex] = {};
                    if (!errors.sections[sectionIndex].divisions) errors.sections[sectionIndex].divisions = {};
                    errors.sections[sectionIndex].divisions[divisionIndex] = errors.sections[sectionIndex].divisions[divisionIndex] || {};
                    errors.sections[sectionIndex].divisions[divisionIndex].noticetitle = "Please enter Notice Title.";
                }
                if (!division.marks) {
                    formIsValid = false;
                    if (!errors.sections) errors.sections = {};
                    if (!errors.sections[sectionIndex]) errors.sections[sectionIndex] = {};
                    if (!errors.sections[sectionIndex].divisions) errors.sections[sectionIndex].divisions = {};
                    errors.sections[sectionIndex].divisions[divisionIndex] = errors.sections[sectionIndex].divisions[divisionIndex] || {};
                    errors.sections[sectionIndex].divisions[divisionIndex].marks = "Please enter Marks.";
                }

                division.question_data.forEach((question, questionIndex) => {
                    if (!question.qtype) {
                        formIsValid = false;
                        if (!errors.sections) errors.sections = {};
                        if (!errors.sections[sectionIndex]) errors.sections[sectionIndex] = {};
                        if (!errors.sections[sectionIndex].divisions) errors.sections[sectionIndex].divisions = {};
                        if (!errors.sections[sectionIndex].divisions[divisionIndex]) errors.sections[sectionIndex].divisions[divisionIndex] = {};
                        if (!errors.sections[sectionIndex].divisions[divisionIndex].questions) errors.sections[sectionIndex].divisions[divisionIndex].questions = {};
                        errors.sections[sectionIndex].divisions[divisionIndex].questions[questionIndex] = errors.sections[sectionIndex].divisions[divisionIndex].questions[questionIndex] || {};
                        errors.sections[sectionIndex].divisions[divisionIndex].questions[questionIndex].question = "Please enter Question.";
                    }
                })
            });
        });
        setError(errors);
        return formIsValid;
    };

    const _getAutoPaperGenerationData = async (limit, offset, papergenfilter) => {
        try {
            setIsLoader(true);
            const { data } = await Api.getRequest(
                `api/auto-paper-generationnew?limit=${limit || 10}&offset=${search ? 1 : offset || 1
                }&search=${search || ""}&board=${papergenfilter?.board?.id || ""
                }&medium=${papergenfilter?.medium?.id || ""}&standard=${papergenfilter?.standard?.id || ""
                }&semester=${papergenfilter?.semester?.id || ""}&subject=${papergenfilter?.subject?.id || ""
                }`
            );
            const req = JSON.parse(data);
            if (req?.status === 200) {
                setPaperGenerationData(req?.data);
                setDataCount(req?.totalCount);
                setIsLoader(false);
            }
        } catch (error) {
            ErrorToast(error?.message);
            setIsLoader(false);
        }
    };

    const handleAddModelSubmit = async () => {
        if (validation()) {
            setIsLoading(true);
            let body = {
                standard: modelPaperGenerationData?.standard?.id,
                semester: modelPaperGenerationData?.semester?.id,
                subject: modelPaperGenerationData?.subject?.id,
                tmarks: modelPaperGenerationData?.totalMarks?.name,
                sectionData: sections,
            };
            if (modelType === "edit") {
                try {
                    const res = await Api.putRequest(
                        `api/auto-paper-generationnew/update/${selectedId}`,
                        body
                    );
                    if (res?.data?.status === 200) {
                        SuccessToast(res?.data?.message);
                        _getAutoPaperGenerationData(
                            recordsPerPage,
                            currentPage,
                            papergenfilter
                        );
                        SetModelPaperGenerationData({
                            board: "",
                            medium: "",
                            standard: "",
                            semester: "",
                            subject: "",
                            totalMarks: "",
                            chapter: [],
                        });
                        setSections(initialData);
                        setIsModel(false);
                        setValue([]);
                        setSelectedId("");
                        setSelectionDataMedium([]);
                        setSelectionDataStandard([]);
                        setSelectionDataSemester([]);
                        setSelectionDataSubject([]);
                        setSelectionDataChapter([]);
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 2000);
                    } else {
                        ErrorToast(res?.data);
                    }
                } catch (error) {
                    ErrorToast(error?.message);
                    setIsLoading(false);
                } finally {
                    setIsLoading(false);
                }
            } else {
                try {
                    const response = await Api.postRequest(
                        "api/auto-paper-generationnew/create",
                        body
                    );
                    const req = JSON.parse(response.data);
                    if (req?.status === 200) {
                        SuccessToast(req?.message);
                        _getAutoPaperGenerationData(
                            recordsPerPage,
                            currentPage,
                            papergenfilter
                        );
                        SetModelPaperGenerationData({
                            board: "",
                            medium: "",
                            standard: "",
                            semester: "",
                            subject: "",
                            totalMarks: "",
                            chapter: [],
                        });
                        setSections(initialData);
                        // setIsModel(false);
                        setSelectedId("");
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 2000);
                    } else {
                        console.log("hello🎈");
                        ErrorToast("Something wrong please check");
                    }
                } catch (error) {
                    ErrorToast(error?.message);
                    setIsLoading(false);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const _deletePaperGeneration = async (id) => {
        try {
            let data;
            if (deleteType === "multi") {
                const body = {
                    ids: checkedItems,
                };
                const response = await Api.postRequest(
                    `api/auto-paper-generationnew/delete`,
                    body
                );
                data = response.data;
            } else {
                const body = {
                    ids: [SelectAutoPaperGenerationId],
                };
                const response = await Api.postRequest(
                    `api/auto-paper-generationnew/delete`,
                    body
                );
                data = response.data;
            }
            const req = JSON.parse(data);
            if (req?.status === 200) {
                SuccessToast(req?.message);
                _getAutoPaperGenerationData(
                    recordsPerPage,
                    currentPage,
                    papergenfilter
                );
                setOpenModel(false);
                setSelectAutoPaperGenerationId("");
            } else {
                ErrorToast(req?.message);
            }
        } catch (error) {
            ErrorToast(error?.message);
        }
    };

    const handleAddSection = () => {
        const newSection = {
            sectionName: `Section ${String.fromCharCode(65 + sections.length)}`,
            division: [{
                marks: 0,
                noticetitle: "",
                question_data: [{
                    question_id: 1,
                    qtype: '',
                    isOr: false,
                    isAnd: false,
                    andId: 0,
                }],
            }]
        };
        setSections(dynamicSequence([...sections, newSection]));
    };

    const handleRemoveSection = (index) => {
        if (sections.length <= 1) return
        const updatedSections = sections.filter((_, i) => i !== index);
        setSections(dynamicSequence(updatedSections));
    };

    const handleAddDivision = (sectionIndex) => {
        const newDivision = {
            marks: 0,
            noticetitle: "",
            question_data: [{
                question_id: 1,
                qtype: '',
                isOr: false,
                isAnd: false,
                andId: 0,
            }],
        };
        const updatedSections = sections.map((section, i) =>
            i === sectionIndex
                ? { ...section, division: [...section.division, newDivision] }
                : section
        );
        setSections(dynamicSequence(updatedSections));
    };

    const handleRemoveDivision = (sectionIndex, divisionIndex) => {
        const updatedSections = sections.map((section, i) => {
            if (i === sectionIndex) {
                if (section.division.length <= 1) {
                    return section;
                }
                return {
                    ...section,
                    division: section.division.filter((_, j) => j !== divisionIndex),
                };
            }
            return section;
        });
        setSections(dynamicSequence(updatedSections));
    };

    const handleSectionChange = (sectionIndex, field, value) => {
        const updatedSections = sections.map((section, i) =>
            i === sectionIndex ? { ...section, [field]: value } : section
        );
        setSections(updatedSections);
    };

    const handleDivisionChange = (sectionIndex, divisionIndex, field, value) => {
        const updatedSections = sections.map((section, i) =>
            i === sectionIndex
                ? {
                    ...section,
                    division: section.division.map((division, j) =>
                        j === divisionIndex ? { ...division, [field]: value } : division
                    )
                }
                : section
        );
        setSections(updatedSections);
    };

    const handleQTypeChange = (sectionIndex, divisionIndex, questionIndex, field, value) => {
        const updatedSections = sections.map((section, i) =>
            i === sectionIndex
                ? {
                    ...section,
                    division: section.division.map((division, j) =>
                        j === divisionIndex
                            ? {
                                ...division,
                                question_data: division.question_data.map((question, k) =>
                                    k === questionIndex ? { ...question, [field]: { id: value?.id, name: value?.name } } : question
                                )
                            }
                            : division
                    )
                }
                : section
        );
        setSections(updatedSections);
    };



    useEffect(() => {
        _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
    }, [recordsPerPage, currentPage]);

    const handleSelectedItems = (selectedItems) => {
        const Array = selectedItems.map((a) => a.id);
        const selectedItemsString = Array.join(", ");
        SetModelPaperGenerationData((oldValue) => ({
            ...oldValue,
            questionType: selectedItemsString,
        }));
    };

    useEffect(() => {
        handleSelectedItems(selectedItems);
    }, [selectedItems]);

    const handleCheckboxChange = (id) => {
        setCheckedItems((prevState) => {
            const isChecked = prevState.includes(id);

            const updatedItems = isChecked
                ? prevState.filter((itemId) => itemId !== id)
                : [...prevState, id];

            return updatedItems;
        });
    };

    const [value, setValue] = useState([]);
    const [values, setValues] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const idsString = newValue.map((option) => parseInt(option.id)).join(",");
        SetModelPaperGenerationData((oldValue) => ({
            ...oldValue,
            questionType: idsString,
        }));
    };

    useEffect(() => {
        if (modelPaperGenerationData?.questionType) {
            let qtypeIds;
            if (typeof modelPaperGenerationData.questionType === "string") {
                qtypeIds = modelPaperGenerationData.questionType.split(",").map(Number);
            } else if (Array.isArray(modelPaperGenerationData.questionType)) {
                qtypeIds = modelPaperGenerationData.questionType.map(Number);
            } else {
                console.error(
                    "Unsupported type for questionType:",
                    modelPaperGenerationData.questionType
                );
                return;
            }
            const selectedOptions = qTypeModalData.filter((option) =>
                qtypeIds.includes(option.id)
            );
            setValue(selectedOptions);
        }
    }, [modelPaperGenerationData, qTypeModalData]);

    const applyFilter = () => {
        _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
    };

    const resetFilter = () => {
        setIsResetFilter(true);
        setpapergenfilter({
            board: "",
            medium: "",
            standard: "",
            semester: "",
            subject: "",
        });
        setMediumData([]);
        setStandardData([]);
        setSemesterData([]);
        setSubjectData([]);
    };

    useEffect(() => {
        if (isResetFilter) {
            _getAutoPaperGenerationData(recordsPerPage, currentPage, papergenfilter);
            setIsResetFilter(false);
        }
    }, [isResetFilter]);

    return (
        <>
            <div className=" w-full sm:px-1 ">
                <div className="w-full sm:px-0 flex items-start gap-x-4 mr-4">
                    <div className="w-full">
                        <Select
                            data={boardData || []}
                            value={papergenfilter?.board}
                            onChange={(e) => handleFilter("board", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Board"
                            type="board"
                            getBoard={getBoardData}
                        />
                    </div>
                    <div className="w-full">
                        <Select
                            data={mediumData || []}
                            value={papergenfilter?.medium}
                            onChange={(e) => handleFilter("medium", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Medium"
                            type="medium"
                            getMedium={getMediumData}
                        />
                    </div>
                    <div className="w-full">
                        <Select
                            data={standardData || []}
                            value={papergenfilter?.standard}
                            onChange={(e) => handleFilter("standard", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Standard"
                            type={"standard"}
                            getStandard={getStandardData}
                        />
                    </div>
                    <div className="w-full">
                        <Select
                            data={semesterData || []}
                            value={papergenfilter?.semester}
                            onChange={(e) => handleFilter("semester", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Semester"
                            type={"semester"}
                            getSemester={getSemesterData}
                        />
                    </div>
                    <div className="w-full">
                        <Select
                            data={subjectData || []}
                            value={papergenfilter?.subject}
                            onChange={(e) => handleFilter("subject", e)}
                            className="font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                            placeholder="All Subject"
                            type={"subject"}
                            getSubject={getSubjectData}
                        />
                    </div>
                    <div className="mt-3">
                        <Button
                            onClick={() => applyFilter()}
                            id="filterbtn"
                            type="button"
                            label={"SEARCH"}
                        />
                    </div>
                    <div className="mt-3">
                        <Button
                            onClick={() => resetFilter()}
                            id=""
                            type="button"
                            label={"RESET"}
                        />
                    </div>
                </div>
                <div className="flex-1 px-2 sm:px-0 w-full">
                    <div>
                        <div className="flex justify-between items-center mt-2">
                            <div>
                                <MasterTitle
                                    title="Auto Paper Flow"
                                    description="Below are the list of all the notice."
                                />
                            </div>
                            <div className="flex items-center space-x-2 overflow-x-auto">
                                <div>
                                    <div style={{ display: "flex", gap: "5px" }}>
                                        {access?.delete && (
                                            <Button
                                                id=""
                                                type="submit"
                                                icon={TrashIcon}
                                                label={"DELETE AUTO PAPER FLOW"}
                                                onClick={() => {
                                                    setDeleteType("multi");
                                                    if (checkedItems.length > 0) {
                                                        setOpenModel(true);
                                                    }
                                                }}
                                            />
                                        )}
                                        {access?.add && (
                                            <Button
                                                id=""
                                                type="submit"
                                                icon={PlusIcon}
                                                label={"ADD AUTO PAPER FLOW"}
                                                onClick={() => {
                                                    setIsModel(true);
                                                    setModelType("add");
                                                }}
                                            />
                                        )}
                                    </div>
                                    <Model
                                        title={
                                            modelType === "add"
                                                ? "Add Auto Paper"
                                                : "Edit Auto Paper"
                                        }
                                        show={isModel}
                                        onCancel={() => {
                                            setIsModel(false);
                                            setValue([]);
                                            setError({});
                                            SetModelPaperGenerationData({});
                                            setSections(initialData);
                                            setSelectedId("");
                                            setSelectionDataMedium([]);
                                            setSelectionDataStandard([]);
                                            setSelectionDataSemester([]);
                                            setSelectionDataSubject([]);
                                            setSelectionDataChapter([]);
                                        }}
                                        onClose={() => {
                                            setIsModel(false);
                                            setValue([]);
                                            setError({});
                                            SetModelPaperGenerationData({});
                                            setSections(initialData);
                                            setSelectedId("");
                                            setSelectionDataMedium([]);
                                            setSelectionDataStandard([]);
                                            setSelectionDataSemester([]);
                                            setSelectionDataSubject([]);
                                            setSelectionDataChapter([]);
                                        }}
                                        onOk={() => {
                                            handleAddModelSubmit();
                                        }}
                                        okText="SUBMIT"
                                        cancelText="CLOSE"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                        width="max-w-3xl"
                                    >
                                        {isModelLoader ? (
                                            <tr
                                                style={{
                                                    height: "120px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6">
                                                    <div className="w-full">
                                                        <Select
                                                            value={modelPaperGenerationData?.board}
                                                            data={selectionDataBoard || []}
                                                            onChange={(e) => handelModelData("board", e)}
                                                            className="font-regular mb-3"
                                                            label="Board"
                                                            required
                                                            placeholder="All Board"
                                                            type={"board"}
                                                            getBoard={getBoardModelData}
                                                            isPage={true}
                                                        />
                                                        <Typography variant="caption" color={"error"}>
                                                            {!modelPaperGenerationData?.board
                                                                ? error?.board
                                                                : ""}
                                                        </Typography>
                                                    </div>
                                                    <div className="w-full">
                                                        <Select
                                                            value={modelPaperGenerationData?.medium}
                                                            data={selectionDataMedium || []}
                                                            onChange={(e) => handelModelData("medium", e)}
                                                            className="font-regular mb-3"
                                                            label="Medium"
                                                            required
                                                            placeholder="All Medium"
                                                            type={"medium"}
                                                            getMedium={getMediumModelData}
                                                            isPage={true}
                                                        />
                                                        <Typography variant="caption" color={"error"}>
                                                            {!modelPaperGenerationData?.medium
                                                                ? error?.medium
                                                                : ""}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                                                    <div className="w-full">
                                                        <Select
                                                            data={selectionDataStandard || []}
                                                            label="Standard"
                                                            required
                                                            placeholder="All Standard"
                                                            disabled={false}
                                                            type={"standard"}
                                                            value={modelPaperGenerationData?.standard || ""}
                                                            onChange={(e) => handelModelData("standard", e)}
                                                            getStandard={getStandardModelData}
                                                            isPage={true}
                                                        />
                                                        <Typography variant="caption" color={"error"}>
                                                            {!modelPaperGenerationData?.standard
                                                                ? error?.standard
                                                                : ""}
                                                        </Typography>
                                                    </div>
                                                    <div className="w-full">
                                                        <Select
                                                            data={selectionDataSemester || []}
                                                            label="Semester"
                                                            required
                                                            placeholder="All semester"
                                                            disabled={false}
                                                            type={"semester"}
                                                            value={modelPaperGenerationData?.semester || ""}
                                                            onChange={(e) => handelModelData("semester", e)}
                                                            getSemester={getSemesterModelData}
                                                            isPage={true}
                                                        />
                                                        <Typography variant="caption" color={"error"}>
                                                            {!modelPaperGenerationData?.semester
                                                                ? error?.semester
                                                                : ""}
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                                                    <div className="w-full">
                                                        <Select
                                                            data={selectionDataSubject || []}
                                                            label="Subject"
                                                            required
                                                            disabled={false}
                                                            placeholder="All subject"
                                                            type={"subject"}
                                                            value={modelPaperGenerationData?.subject || ""}
                                                            onChange={(e) => handelModelData("subject", e)}
                                                            getSubject={getSubjectModelData}
                                                            isPage={true}
                                                        />
                                                        <Typography variant="caption" color={"error"}>
                                                            {!modelPaperGenerationData?.subject
                                                                ? error?.subject
                                                                : ""}
                                                        </Typography>
                                                    </div>

                                                    <div className="w-full">
                                                        <Select
                                                            label="Total Marks"
                                                            required
                                                            disabled={false}
                                                            placeholder="All Marks"
                                                            type={"totalMarks"}
                                                            value={modelPaperGenerationData?.totalMarks || ""}
                                                            onChange={(e) => handelModelData("totalMarks", e)}
                                                            data={TotalMarksData || []}
                                                        />
                                                        <Typography variant="caption" color={"error"}>
                                                            {!modelPaperGenerationData?.totalMarks
                                                                ? error?.totalMarks
                                                                : ""}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <h2 className="text-2xl font-bold mb-4">{viewSection ? "Sections" : "Divisions"}</h2>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Checkbox
                                                                type="checkbox"
                                                                checked={viewSection}
                                                                onChange={(e) => handleSectionShow(e.target.checked)}
                                                            />
                                                            <span className="ml-2">With Section</span>
                                                        </div>
                                                    </div>
                                                    {sections.map((section, sectionIndex) => (
                                                        <div
                                                            key={sectionIndex}
                                                            className="mb-6 border-b pb-4 relative"
                                                            style={{
                                                                padding: "15px",
                                                                borderRadius: "9px",
                                                                border: "1px solid rgb(211 212 213)",
                                                                // backgroundColor: "ghostwhite",
                                                            }}>
                                                            {viewSection && <> <div className="flex justify-between items-center">
                                                                <h4 className="text-lg font-medium">
                                                                    {`Section ${String.fromCharCode(65 + sectionIndex)}`}
                                                                </h4>
                                                                <IconButton onClick={() => handleRemoveSection(sectionIndex)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </div>
                                                                <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                                                                    <div className="w-full flex flex-col">
                                                                        <Input
                                                                            required
                                                                            placeholder="Section Label"
                                                                            label="Section Label"
                                                                            value={section.section}
                                                                            onChange={(e) =>
                                                                                handleSectionChange(sectionIndex, "section", e.target.value)
                                                                            }
                                                                            className="w-full"
                                                                        />
                                                                        {error.sections?.[sectionIndex]?.section && (
                                                                            <p className="text-red-600 text-sm">
                                                                                {error.sections[sectionIndex].section}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>}
                                                            {section.division.map((division, divisionIndex) => (
                                                                <div
                                                                    key={divisionIndex}
                                                                    className="mt-4 mb-2"
                                                                    style={{
                                                                        padding: "7px 14px",
                                                                        borderRadius: "9px",
                                                                        backgroundColor: "rgb(18 144 228 / 10%)",

                                                                    }}>
                                                                    <div className="flex justify-between items-center">
                                                                        <h4 className="text-lg font-medium">
                                                                            {`Division ${String.fromCharCode(65 + divisionIndex)}`}
                                                                        </h4>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleRemoveDivision(sectionIndex, divisionIndex)
                                                                            }
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </div>

                                                                    <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                                                                        <div className="w-full flex flex-col">
                                                                            <Input
                                                                                required
                                                                                placeholder="Notice Title"
                                                                                label="Notice Title"
                                                                                value={division.noticetitle}
                                                                                onChange={(e) =>
                                                                                    handleDivisionChange(
                                                                                        sectionIndex,
                                                                                        divisionIndex,
                                                                                        "noticetitle",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                                className="w-full"
                                                                            />
                                                                            {error.sections?.[sectionIndex]?.divisions?.[divisionIndex]?.noticetitle && (
                                                                                <p className="text-red-600 text-sm">
                                                                                    {error.sections[sectionIndex].divisions[divisionIndex].noticetitle}
                                                                                </p>
                                                                            )}
                                                                        </div>

                                                                        <div className="w-full flex flex-col">
                                                                            <Input
                                                                                required
                                                                                placeholder="Division Marks"
                                                                                label="Division Marks"
                                                                                value={division.marks}
                                                                                type={"number"}
                                                                                onChange={(e) =>
                                                                                    handleDivisionChange(
                                                                                        sectionIndex,
                                                                                        divisionIndex,
                                                                                        "marks",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                                className="w-full"
                                                                            />
                                                                            {error.sections?.[sectionIndex]?.divisions?.[divisionIndex]?.marks && (
                                                                                <p className="text-red-600 text-sm">
                                                                                    {error.sections[sectionIndex].divisions[divisionIndex].marks}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center flex-col space-y-7 sm:flex-row sm:space-y-0 sm:space-x-6  mb-3">
                                                                        <div className="w-full flex flex-col">
                                                                            <p className="mb-2 block text-sm font-medium text-[#252F40] font-poppins">Question Type</p>
                                                                            {division?.question_data?.map((qType, qTypeIndex) => {
                                                                                const alphabetLetter = qType?.andId ? String.fromCharCode(65 + (qType?.andId - 1 || 0)) : ''
                                                                                return (<>
                                                                                    {qType?.isOr &&
                                                                                        <span
                                                                                            className=" block text-sm font-medium text-[#252F40] font-poppins"
                                                                                            style={{ textAlign: 'center' }}>OR</span>}
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <div className="mr-2 mt-3" style={{ display: 'flex' }}>
                                                                                            <span>{qType?.question_id}</span>
                                                                                            <span style={{ marginRight: alphabetLetter ? '0px' : '16px' }}>{alphabetLetter ? ` (${alphabetLetter})` : '.'}</span>
                                                                                        </div>
                                                                                        <div className="w-full flex flex-col">
                                                                                            <Select
                                                                                                data={qTypeModalData || []}
                                                                                                value={qType?.qtype || ""}
                                                                                                disabled={false}
                                                                                                placeholder="All Question Type"
                                                                                                onChange={(newValue) =>
                                                                                                    handleQTypeChange(
                                                                                                        sectionIndex,
                                                                                                        divisionIndex,
                                                                                                        qTypeIndex,
                                                                                                        "qtype",
                                                                                                        newValue,
                                                                                                    )
                                                                                                }
                                                                                                className=" font-regular hover:outline-none hover:border-[#138CE3] hover:ring-[#138CE3]"
                                                                                                type={"qType"}
                                                                                                getQType={getQTypeModalData}
                                                                                            />
                                                                                            {error.sections?.[sectionIndex]?.divisions?.[divisionIndex]?.questions?.[qTypeIndex]?.question && (
                                                                                                <p className="text-red-600 text-sm">
                                                                                                    {error.sections?.[sectionIndex]?.divisions?.[divisionIndex]?.questions?.[qTypeIndex]?.question}
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                        <IconButton
                                                                                            style={{ marginTop: '9px' }}
                                                                                            onClick={() =>
                                                                                                handleRemoveQuestion(sectionIndex, divisionIndex, qTypeIndex)
                                                                                            }
                                                                                        >
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </div>

                                                                                </>
                                                                                )
                                                                            })}
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '16px', gap: '5px' }}>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    divStyle={{ width: '-webkit-fill-available' }}
                                                                                    onClick={() => handleAddQuestion(sectionIndex, divisionIndex, false, false)}
                                                                                    className="mt-4"
                                                                                    type="button"
                                                                                    label={"Add Question"}
                                                                                    icon={PlusIcon}
                                                                                >
                                                                                    Add Question
                                                                                </Button>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    divStyle={{ width: '-webkit-fill-available' }}
                                                                                    onClick={() => handleAddQuestion(sectionIndex, divisionIndex, true, false)}
                                                                                    className="mt-4"
                                                                                    type="button"
                                                                                    label={"OR"}
                                                                                >
                                                                                    OR
                                                                                </Button>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    divStyle={{ width: '-webkit-fill-available' }}
                                                                                    onClick={() => handleAddQuestion(sectionIndex, divisionIndex, false, true)}
                                                                                    className="mt-4"
                                                                                    type="button"
                                                                                    label={"AND"}
                                                                                >
                                                                                    AND
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            ))}

                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => handleAddDivision(sectionIndex)}
                                                                className="mt-4"
                                                                type="button"
                                                                label={"Add Division"}
                                                            >
                                                                Add Division
                                                            </Button>
                                                        </div>
                                                    ))}

                                                    {viewSection && <Button
                                                        type="button"
                                                        label={"Add Section"}
                                                        variant="contained"
                                                        onClick={handleAddSection}>
                                                        Add Section
                                                    </Button>}
                                                </div>
                                            </>
                                        )}
                                    </Model>
                                </div>
                            </div>
                        </div>

                        <div className="sm:mb-0 overflow-hidden">
                            <div className="flex flex-col mt-2">
                                <section
                                    className="bg-white rounded-2xl flex flex-col shadow-sm "
                                    style={{
                                        width: "100%",
                                        height: "75vh",
                                        margin: "0 auto",
                                        padding: "20px",
                                    }}
                                >
                                    {" "}
                                    <div className="flex justify-between items-center mt-3 px-8 mb-2">
                                        <div style={{ display: "flex", gap: '5px' }}>
                                            <Input
                                                id=""
                                                name="Search"
                                                type="text"
                                                placeholder="Search here..."
                                                autoComplete="given-name"
                                                required
                                                className="w-72"
                                                onChange={(e) => {
                                                    setSearch(e?.target?.value);
                                                    setCurrentPage(1);
                                                }}
                                                value={search}
                                            />
                                            <Button
                                                onClick={() => applyFilter()}
                                                id=""
                                                type="button"
                                                // label={"SEARCH"}
                                                searchicon={true}
                                            />
                                        </div>
                                        <Records
                                            setCurrentPage={setCurrentPage}
                                            recordsPerPage={recordsPerPage}
                                            setRecordsPerPage={setRecordsPerPage}
                                            dataCount={dataCount}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            overflowY: "auto",
                                            maxHeight: "58vh",
                                            height: "60vh",
                                            width: "100%",
                                        }}
                                    >
                                        {" "}
                                        {!isLoader && PaperGenerationData?.length <= 0 ? (
                                            <NoDataFound />
                                        ) : (
                                            <table style={{ width: "100%" }}>
                                                <thead
                                                    style={{
                                                        position: "sticky",
                                                        top: 0,
                                                        backgroundColor: "#f4f7f8",
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            borderBottom: "1px solid #dddddd",
                                                            textAlign: "left",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                            color: "#7B809A",
                                                            textTransform: "uppercase",
                                                            backgroundColor: "#f4f7f8",
                                                        }}
                                                    >
                                                        {" "}
                                                        <th
                                                            scope="col"
                                                            style={{ width: "3%", padding: "10px" }}
                                                        ></th>
                                                        {titleData.map(
                                                            (v, ind) =>
                                                                v?.title && (
                                                                    <th
                                                                        key={ind}
                                                                        style={{
                                                                            padding: "12px",
                                                                            backgroundColor: "#f4f7f8",
                                                                            position:
                                                                                v.title === "EDIT" ||
                                                                                    v.title === "DELETE"
                                                                                    ? "sticky"
                                                                                    : "",
                                                                            right:
                                                                                v.title === "EDIT"
                                                                                    ? "68px"
                                                                                    : v.title === "DELETE"
                                                                                        ? "0"
                                                                                        : "",
                                                                        }}
                                                                    >
                                                                        <div className="flex justify-between">
                                                                            <span>{v.title}</span>
                                                                            {v.title !== "EDIT" &&
                                                                                v.title !== "DELETE" && (
                                                                                    <span>
                                                                                        <ChevronUpDownIcon
                                                                                            onClick={() => {
                                                                                                let res = SortData(
                                                                                                    PaperGenerationData,
                                                                                                    v.fieldName,
                                                                                                    v.sort
                                                                                                );

                                                                                                let copyData = [...titleData];
                                                                                                copyData[ind].sort =
                                                                                                    v.sort > 0 ? -1 : 1;
                                                                                                setTitleData(copyData);
                                                                                                setPaperGenerationData(res);
                                                                                            }}
                                                                                            className="w-5 h-5"
                                                                                        />
                                                                                    </span>
                                                                                )}
                                                                        </div>
                                                                    </th>
                                                                )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200">
                                                    {isLoader ? (
                                                        <tr style={{ height: "25rem" }}>
                                                            <td colSpan={9} style={{ textAlign: "center" }}>
                                                                <CircularProgress />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        PaperGenerationData?.map((data, id) => (
                                                            <tr
                                                                key={id}
                                                                className="my-3 px-3 text-[#000] font-bold text-[13px]"
                                                            >
                                                                <td className="px-6 py-2.5 whitespace-no-wrap">
                                                                    <Checkbox
                                                                        checked={checkedItems.includes(data.id)}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(data.id)
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="lg:table-cell whitespace-nowrap py-2.5 px-8">
                                                                    {data?.standardInfo?.name}
                                                                </td>
                                                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                                                    {data?.semesterInfo?.semester}
                                                                </td>
                                                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                                                    {data?.subjectInfo?.subject}
                                                                </td>
                                                                <td className="lg:table-cell whitespace-nowrap px-8 py-2.5 ">
                                                                    {data?.tmarks}
                                                                </td>
                                                                {access?.edit && (
                                                                    <td
                                                                        className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                                                        style={{
                                                                            position: "sticky",
                                                                            right: "68px",
                                                                            backgroundColor: "white",
                                                                        }}
                                                                    >
                                                                        <ActionButton
                                                                            key={data.key}
                                                                            action="edit"
                                                                            onClick={() => {
                                                                                setIsModel(true);
                                                                                setModelType("edit");
                                                                                setSelectedId(data.id);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                )}
                                                                {access?.delete && (
                                                                    <td
                                                                        className="relative whitespace-nowrap  px-6 py-2.5  text-left"
                                                                        style={{
                                                                            position: "sticky",
                                                                            right: "0",
                                                                            backgroundColor: "white",
                                                                        }}
                                                                    >
                                                                        <ActionButton
                                                                            key={data.key}
                                                                            action="delete"
                                                                            onClick={() => {
                                                                                setDeleteType("single");
                                                                                setOpenModel(true);
                                                                                setSelectAutoPaperGenerationId(
                                                                                    data?.id
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        recordsPerPage={recordsPerPage}
                                        data={PaperGenerationData}
                                        dataCount={dataCount}
                                        numbers={numbers}
                                        npage={npage}
                                        lastIndex={lastIndex}
                                        firstIndex={firstIndex}
                                    />
                                </section>
                            </div>

                            {/* Delete */}
                            <DeletePopup
                                title={"Delete"}
                                show={openModel}
                                description="Are you sure you want to delete this Data ? All data
            of this Data will be permanently removed from our
            servers forever. This action cannot be undone."
                                onCancel={() => {
                                    setOpenModel(false);
                                    setSelectAutoPaperGenerationId("");
                                }}
                                onClose={() => {
                                    setOpenModel(false);
                                    setSelectAutoPaperGenerationId("");
                                }}
                                onDelete={() => {
                                    _deletePaperGeneration();
                                }}
                                cancelButtonRef={cancelButtonRef}
                                okText="Delete"
                                cancelText="Cancel"
                            />
                            {/* Delete */}
                            <FloatNotification
                                show={showFloatNotification}
                                title={"Successfully deleted!"}
                                type="success"
                                description={"Data Deleted."}
                                onClick={() => {
                                    setShowFloatNotification(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AutoPaperFlow;
