
import Tabs from "./Tabs";
import TeacherJob from "./TeacherJob";
import InstituteVacancy from "./InstituteVacancy";
import JobBanner from "./JobBanner";
import JobPosition from "./JobPosition";
import JobComment from "./JobComment";
import JobRequest from "./JobRequest";
import VacancyRequest from "./VacancyRequest";

const JobModule = (props) => {
  let categories = [
    {
      name: "Pending Job Request",
      component: <JobRequest access={props?.access} />,
      path: "/job/pending-request",
    },
    {
      name: "Pending Vacancy Request",
      component: <VacancyRequest access={props?.access} />,
      path: "/job/pending-vacancy-request",
    },
    {
      name: "Teacher Job",
      component: <TeacherJob access={props?.access} />,
      path: "/job/teacher-job",
    },
    {
      name: "Institute Vacancy",
      component: <InstituteVacancy access={props?.access} />,
      path: "/job/institute-vacancy",
    },
    {
      name: "Job Banner",
      component: <JobBanner access={props?.access} />,
      path: "/job/job-banners",
    },
    {
      name: "Job Position",
      component: <JobPosition access={props?.access} />,
      path: "/job/job-position",
    },
    {
      name: "Job Comment",
      component: <JobComment access={props?.access} />,
      path: "/job/job-comment",
    },
  ];
  return <Tabs categories={categories} title="Job" />;
};

export default JobModule;
